import { Organization, Product } from '@shared/generated/graphql';

export function hasProducts(organizationDetails: Organization[]) {
  return {
    hasOrders: Boolean(
      organizationDetails.find((org) => org.products.includes(Product.ORDERS))
    ),
    hasQuotes: Boolean(
      organizationDetails.find((org) => org.products.includes(Product.QUOTES))
    ),
    hasEmailScanning: Boolean(
      organizationDetails.find((org) =>
        org.products.includes(Product.EMAIL_SCANNING)
      )
    ),
    hasWorkers: Boolean(
      organizationDetails.find((org) => org.products.includes(Product.WORKERS))
    ),
  };
}
