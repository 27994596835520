import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { EditableJSONField } from '../../types';
import { SimpleArray } from '../simple-array/SimpleArray';
import { CommodityIndex } from './CommodityIndex';

const ROOT = makeRootClassName('CommodityIndexArray');
export const el = makeElementClassNameFactory(ROOT);

export type CommodityIndexElement = {
  index: EditableJSONField;
};

export type CommodityIndexArrayData = {
  _value: CommodityIndexElement[];
  _added?: CommodityIndexElement[];
  _idxs?: string[];
};

type CommodityIndexArrayProps = {
  data: CommodityIndexArrayData;
  prefix?: string;
};

export function CommodityIndexArray({
  data,
  prefix,
}: CommodityIndexArrayProps) {
  return (
    <div className="flex w-full flex-col gap-1">
      <Text className="text-gray-500" isHeavy type="body-xs">
        Commodities
      </Text>
      <SimpleArray
        data={data}
        ElementComponent={CommodityIndex}
        createNewElement={createCommodityIndexElement}
        description="Commodity"
        prefix={prefix}
      />
    </div>
  );
}

function createCommodityIndexElement(): CommodityIndexElement {
  return {
    index: {
      _value: '',
      _display: {
        name: '',
      },
      _corrected: '',
      _logProbs: [],
    },
  };
}
