import { IntegrationConfig } from '@shared/types/order';
import { QuoteFormValues } from '@shared/utils/forms/types';
import { createContext, useContext } from 'react';
import { Path } from 'react-hook-form';

type QuoteFormAdaptorContextType = {
  save: (
    nameOrNames: Path<QuoteFormValues> | Path<QuoteFormValues>[]
  ) => Promise<void>;
  loading: boolean;
  integrationConfigs: IntegrationConfig[];
};

export const QuoteFormAdaptorContext =
  createContext<QuoteFormAdaptorContextType>({
    loading: false,
    save: async () => {
      return;
    },
    integrationConfigs: [],
  });

export function useQuoteFormAdaptorContext() {
  const context = useContext(QuoteFormAdaptorContext);
  if (!context) {
    throw new Error(
      'useQuoteFormAdaptorContext must be used within a QuoteFormAdaptorContext.Provider'
    );
  }
  return context;
}
