import {
  ConfigOverride,
  ConfigOverrides,
  IntegrationConfigs,
} from '@shared/types/order';
import {
  DEFAULT_REQUIRED_FIELDS,
  RequiredField,
} from 'clerk_common/requiredFields';
import { Address } from 'clerk_common/types/address';
import { get, isEqual, isNil } from 'lodash';

export const validateAddressHasRequiredFields = (
  address: Address,
  requiredFields: RequiredField[]
): boolean => {
  return requiredFields.every((field) => {
    if (typeof field === 'string') {
      return !!get(address, field);
    } else {
      return field.some((option) => option.every((f) => !!get(address, f)));
    }
  });
};

export const filterRequiredFields = (
  fields: RequiredField[],
  path: string
): RequiredField[] => {
  return fields.reduce<RequiredField[]>((acc, field) => {
    if (typeof field === 'string') {
      if (field.startsWith(path)) {
        acc.push(field);
      }
    } else if (Array.isArray(field)) {
      const filteredOptions = field
        .map((option) => option.filter((f) => f.startsWith(path)))
        .filter((option) => option.length > 0);

      if (filteredOptions.length > 0) {
        acc.push(filteredOptions);
      }
    }
    return acc;
  }, []);
};

export const filterAndStripRequiredFields = (
  fields: RequiredField[],
  path: string
): RequiredField[] => {
  return fields.reduce<RequiredField[]>((acc, field) => {
    if (typeof field === 'string') {
      const suffix = getRequiredFieldSuffix(field, path);
      if (suffix) {
        acc.push(suffix);
      }
    } else if (Array.isArray(field)) {
      const filteredOptions = field
        .map((option) =>
          option.map((f) => getRequiredFieldSuffix(f, path)).filter((f) => f)
        )
        .filter((option) => option.length > 0);

      if (filteredOptions.length > 0) {
        acc.push(filteredOptions);
      }
    }
    return acc;
  }, []);
};

export const getRequiredFieldSuffix = (
  requiredField: string,
  path: string
): string => {
  const requiredFieldParts = requiredField.split('.');
  const pathParts = path.split('.');
  if (isEqual(requiredFieldParts.slice(0, pathParts.length), pathParts)) {
    if (requiredField === path) {
      return '';
    } else {
      return requiredField.substring(path.length + 1);
    }
  }

  return '';
};

const flattenOneOfs = (requiredFields: RequiredField[]): string[] =>
  requiredFields.flat(2);

// TODO(jacob): Dedup from backend definition
const selectOverride = (
  overrides?: ConfigOverrides | null
): ConfigOverride | undefined => {
  if (isNil(overrides?.integrations) || overrides?.integrations.length === 0) {
    return undefined;
  }
  // TODO(jacob): Use the appropriate config based on the order type.
  return overrides?.integrations[0];
};

export function getRequiredFields(
  integrationConfigs: IntegrationConfigs,
  overrides?: ConfigOverrides
) {
  const override = selectOverride(overrides);
  if (!isNil(override) && !isNil(override?.requiredFields)) {
    return flattenOneOfs(override.requiredFields);
  }

  if (integrationConfigs.integrations.length === 0) {
    return [...DEFAULT_REQUIRED_FIELDS];
  }

  // NOTE(max): It's possible for people to save integration configs
  // without required fields even though they're required in the type.
  // We shouldn't crash if this happens.
  if (isNil(integrationConfigs.integrations[0].requiredFields)) {
    return [];
  }

  // TODO(mike): Select this as a function of order type.
  return flattenOneOfs(integrationConfigs.integrations[0].requiredFields);
}
