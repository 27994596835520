import { Button } from '@shared/components/button';
import { Popover } from '@shared/components/popover';
import { Text } from '@shared/components/text';
import { RateMetadata } from '@shared/types/quote';
import { makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import { PiInfoBold } from 'react-icons/pi';
import { DATEscalationLocation, DATRateResponse } from './types';

const ROOT = makeRootClassName('DATMoreInfo');

const extractDATCompaniesAndReports = (response: DATRateResponse) => {
  const companies = response.rate.companies;
  const reports = response.rate.reports;

  return `${companies} companies | ${reports} reports`;
};

const extractDATMarket = (location: DATEscalationLocation) => {
  return `${location.name} (${enumToString(location.type)})`;
};

const extractDATMarketsInformation = (response: DATRateResponse) => {
  const { escalation } = response;

  if (!escalation) return null;

  return (
    <div>
      <Text type="body-xs">
        <strong>Origin:</strong> {extractDATMarket(escalation.origin)}
      </Text>
      <Text type="body-xs">
        <strong>Destination:</strong> {extractDATMarket(escalation.destination)}
      </Text>
    </div>
  );
};

export const DATRateMoreInfo = ({ metadata }: { metadata?: RateMetadata }) => {
  if (!metadata) return null;

  return (
    <Popover
      content={
        <div className={ROOT}>
          <Text type="body-xs" isHeavy>
            DAT rate information
          </Text>
          <div>{extractDATMarketsInformation(metadata.response)}</div>
          <Text type="body-xs">
            {extractDATCompaniesAndReports(metadata.response)}
          </Text>
        </div>
      }
      side="top"
    >
      <Button
        size="xs"
        tooltip="See DAT rating information"
        isGhost
        icon={<PiInfoBold size={12} />}
      />
    </Popover>
  );
};
