import { LazyQueryExecFunction } from '@apollo/client';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import {
  AuthTokenType,
  Exact,
  GetAuthorizedServiceQuery,
  useGetAuthorizedServiceLazyQuery,
  useGetAuthorizedServiceQuery,
} from '@shared/generated/graphql';

type HasAuthorizedIntegrationState = {
  authorized: boolean;
  authorizedEmail?: string;
  loading: boolean;
};

type HasAuthorizedIntegrationActions = {
  refetch: () => void;
};

type UseHasAuthorizedIntegrationProps = {
  type: AuthTokenType;
};

export const useHasAuthorizedIntegration = (
  p: UseHasAuthorizedIntegrationProps
): HasAuthorizedIntegrationActions & HasAuthorizedIntegrationState => {
  const { isAuthenticated } = useAuthContext();

  const { data, loading, refetch } = useGetAuthorizedServiceQuery({
    skip: !isAuthenticated,
    variables: { type: p.type },
    pollInterval: 30 * 1000,
  });

  const authorized = Boolean(data?.hasAuthorizedServiceType?.success);
  const authorizedEmail = data?.hasAuthorizedServiceType?.email ?? '';

  return {
    authorized,
    authorizedEmail,
    loading,
    refetch,
  };
};

export const useLazyHasAuthorizedIntegration = (): {
  getAuthorizedServiceQuery: LazyQueryExecFunction<
    GetAuthorizedServiceQuery,
    Exact<{
      type: AuthTokenType;
    }>
  >;
} & HasAuthorizedIntegrationActions &
  HasAuthorizedIntegrationState => {
  const [getAuthorizedServiceQuery, { data, loading, refetch }] =
    useGetAuthorizedServiceLazyQuery({
      pollInterval: 30 * 1000,
    });

  const authorized = Boolean(data?.hasAuthorizedServiceType?.success);
  const authorizedEmail = data?.hasAuthorizedServiceType?.email ?? '';

  return {
    getAuthorizedServiceQuery,
    authorized,
    authorizedEmail,
    loading,
    refetch,
  };
};
