import { BasicUserFragment, UserFragment } from '@shared/generated/graphql';
import { toName, User } from '@shared/types/user';
import { UserFeatures } from 'clerk_common/types';
import { isNil } from 'lodash';

export function basicUserFromFragment(fragment?: BasicUserFragment | null) {
  if (isNil(fragment)) return;
  return {
    id: fragment.id,
    email: fragment.email ?? undefined,
    firstName: fragment.firstName ?? undefined,
    lastName: fragment.lastName ?? undefined,
  };
}

export function userFromFragment(fragment: UserFragment): User {
  return {
    id: fragment.id,
    userName: fragment.username ?? '',
    name: toName({
      firstName: fragment.firstName,
      lastName: fragment.lastName,
    }),
    email: fragment.email ?? undefined,
    roles: fragment.roles,
    features: fragment.features as UserFeatures,
  };
}
