import { DraftResponse } from '@shared/components/draft-response/DraftResponse';
import { ResponseDraftContext } from '@shared/contexts/ResponseDraftContext';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useCallback, useContext } from 'react';

type QuoteDraftResponseProps = {
  compact?: boolean;
  onEditSettings?: () => void;
};

export function QuoteDraftResponse({
  compact,
  onEditSettings,
}: QuoteDraftResponseProps) {
  const { draft, draftIsLoading, trackingQuoteId, batchCount } =
    useContext(ResponseDraftContext);
  const { track } = useAnalytics();

  const onCopy = useCallback(() => {
    track('Clicked Copy Draft Response', {
      quoteId: trackingQuoteId,
      count: batchCount,
    });
  }, [trackingQuoteId, batchCount, track]);

  return (
    <DraftResponse
      compact={compact}
      draft={draft}
      draftIsLoading={draftIsLoading}
      onCopy={onCopy}
      onEditSettings={onEditSettings}
    />
  );
}

export default QuoteDraftResponse;
