export const formatNumber = (
  num: number | undefined,
  maximumSignificantDigits = 2
): string => {
  if (num || num === 0)
    return num.toLocaleString('en-US', {
      maximumSignificantDigits,
    });
  return '';
};

export const usdFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const usdFormatterNoCents = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const formatDollars = (num: number): string => {
  // format using usdFormatter, rounded dollars with no cents
  if (!num) return '';
  return usdFormatterNoCents.format(num);
};

export const formatPercentage = (decimal: number): string => {
  // format decimal number as percentage, e.g. 0.5 => 50, 0.6666 => 67
  const percentage = (decimal * 100).toFixed(0);
  return `${percentage}%`;
};

export const formatPercentageWithParentheticalNegatives = (
  decimal: number
): string => {
  const percentage = Math.abs(decimal * 100).toFixed(0);
  if (decimal < 0) {
    return `(${percentage}%)`;
  }
  return `${percentage}%`;
};
