import { Button, CircularProgress, CopyButton, Text } from '@shared/components';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { PiGear } from 'react-icons/pi';

type DraftResponseProps = {
  compact?: boolean;
  onCopy?: () => void;
  onEditSettings?: () => void;
  draft?: string;
  draftIsLoading?: boolean;
};

export function DraftResponse({
  compact,
  onCopy,
  onEditSettings,
  draft,
  draftIsLoading,
}: DraftResponseProps) {
  if (draftIsLoading)
    return (
      <div className="flex min-h-16 w-full flex-row items-center justify-center">
        <CircularProgress className="text-gray-600" size="xs" />
      </div>
    );

  if (isNil(draft)) {
    return null;
  }

  return (
    <div>
      <div
        className={clsx(
          'rounded-md border-[1px] shadow-lg',
          compact ? 'm-2 p-2' : 'm-[16px] p-[16px]'
        )}
      >
        <div
          className={clsx(
            'flex flex-row justify-between',
            compact && 'gap-2 p-2'
          )}
        >
          <Text isHeavy type="body-sm" className="mb-[8px] text-gray-500">
            Draft Response
          </Text>
          <div className="flex flex-row gap-[8px]">
            {onEditSettings && (
              <Button
                isGhost
                size="xs"
                icon={<PiGear size={20} />}
                tooltip="Draft reply settings"
                onPress={onEditSettings}
              />
            )}
            <CopyButton
              size="xs"
              isGhost
              textToCopy={draft}
              onPressEnd={onCopy}
            />
          </div>
        </div>
        <div className={clsx(compact && 'p-2')}>
          {draft.split('\n').map((line, i) => (
            <Text key={i} type="body-xs" className="mb-[8px]">
              {line || '\u00A0'}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );
}
