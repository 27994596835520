import {
  AuthTokenType,
  IntegrationCredentialsType,
  UsageIndicator,
} from '@shared/generated/graphql';
import { useOrganizationHasCredential } from '@shared/graphql/hooks/useOrganizationHasCredential';
import { createContext, useContext } from 'react';
import { useMeContext } from '../hooks/useMeContext';
import { useHasAuthorizedIntegration } from './useHasAuthorizedIntegration';

type AuthorizedIntegrationState = {
  integrationName: string;
  authorized: boolean;
  authorizedEmail?: string;
  loading: boolean;
  renderAuthInstructions: () => JSX.Element;
};

type AuthorizedIntegrationActions = {
  refetch: () => void;
};

export type AuthorizedIntegrationContextType = AuthorizedIntegrationState &
  AuthorizedIntegrationActions;

export const AuthorizedIntegrationContext =
  createContext<AuthorizedIntegrationContextType>(buildEmptyContext());

type AuthorizedIntegrationProviderProps = {
  children: JSX.Element;
  type: AuthTokenType;
  renderAuthInstructions: () => JSX.Element;
};
export const AuthorizedIntegrationProvider = ({
  children,
  type,
  renderAuthInstructions,
}: AuthorizedIntegrationProviderProps) => {
  const state = useHasAuthorizedIntegration({ type });
  const value = {
    integrationName: toIntegrationName(type),
    ...state,
    renderAuthInstructions,
  };
  return (
    <AuthorizedIntegrationContext.Provider value={value}>
      {children}
    </AuthorizedIntegrationContext.Provider>
  );
};

type OrgAuthorizatedIntegrationProviderProps = {
  children: JSX.Element;
  type: IntegrationCredentialsType;
  renderAuthInstructions: () => JSX.Element;
};

export const OrgAuthorizedIntegrationProvider = ({
  children,
  type,
  renderAuthInstructions,
}: OrgAuthorizatedIntegrationProviderProps) => {
  const { defaultOrgId } = useMeContext();
  const { hasCredential, loading, refetch } = useOrganizationHasCredential({
    organizationId: defaultOrgId ?? '',
    type,
    usageIndicator: UsageIndicator.PRODUCTION,
  });

  const value = {
    authorizedEmail: 'Organization Admin',
    authorized: hasCredential ?? false,
    loading,
    refetch,
    integrationName: type,
    renderAuthInstructions,
  };
  return (
    <AuthorizedIntegrationContext.Provider value={value}>
      {children}
    </AuthorizedIntegrationContext.Provider>
  );
};

export function useAuthorizedIntegrationContext() {
  const context = useContext(AuthorizedIntegrationContext);
  if (!context) {
    throw new Error(
      'AuthorizedIntegrationContext must be used within a AuthorizedIntegrationProvider'
    );
  }
  return context;
}

function buildEmptyContext(): AuthorizedIntegrationContextType {
  return {
    integrationName: 'Unknown',
    authorized: false,
    loading: false,
    refetch: () => {},
    renderAuthInstructions: () => <></>,
  };
}

function toIntegrationName(type: AuthTokenType): string {
  switch (type) {
    case AuthTokenType.MICROSOFT_GRAPH_API:
      return 'Outlook';
    case AuthTokenType.GMAIL_API:
      return 'Gmail';
    default:
      return 'Unknown';
  }
}
