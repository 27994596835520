import { InteractableComponent } from '@shared/components/interactable-component';
import { Text } from '@shared/components/text';
import { RatingMethod } from '@shared/generated/graphql';
import { intersperseElements } from '@shared/plugin/utils/intersperseElements';
import { RateMetadata } from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  getMileageRate,
  makeRateDetailSubtitleComponents,
  shouldShowRateValue,
} from '@shared/utils/rates/rateDetails';
import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import { RateConfidence, RateWidget } from '../RateWidgets';
import { DATRateMoreInfo } from '../rating-widgets/components/dat-more-info/DATMoreInfo';

const ROOT = makeRootClassName('RateDetail');
const el = makeElementClassNameFactory(ROOT);

type RateDetailProps = {
  rate: number;
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
  label: string;
  onPress: () => void;
  isDisabled?: boolean;
};

const getMoreInfoTooltip = ({
  metadata,
  ratingMethod,
}: {
  metadata?: RateMetadata;
  ratingMethod?: RatingMethod;
}) => {
  if (!metadata || !ratingMethod) return null;

  switch (ratingMethod) {
    case RatingMethod.DAT:
      if (metadata?.ratingMethod === 'HISTORY') return null;
      return <DATRateMoreInfo metadata={metadata} />;
    default:
      return null;
  }
};

export const RateDetail = ({
  rate,
  ratingMethod,
  metadata,
  label,
  onPress,
  isDisabled,
}: RateDetailProps) => {
  const mileageRate = getMileageRate({ metadata });

  return (
    <div className={ROOT}>
      <InteractableComponent onPress={onPress} isDisabled={isDisabled}>
        <div className={el`rate-container`}>
          <div className={el`rate-detail`}>
            <div className={el`rate-info`}>
              <div className={el`rate-label`}>
                <Text type="body-xs">{label}</Text>
                {getMoreInfoTooltip({ metadata, ratingMethod })}
              </div>
              {shouldShowRateValue({ metadata }) && (
                <div className={el`rate-value`}>
                  <Text isHeavy type="body-xs">
                    {usdFormatterNoCents.format(rate)}
                  </Text>
                  <Text className={el`mileage-rate`} type="custom">
                    {mileageRate}
                  </Text>
                </div>
              )}
              <Text type="custom" className={el`rate-subtitle`}>
                {intersperseElements(
                  makeRateDetailSubtitleComponents({
                    ratingMethod,
                    metadata,
                  }),
                  'custom'
                )}
              </Text>
            </div>
            <div className={el`rate-confidence`}>
              <RateConfidence ratingMethod={ratingMethod} metadata={metadata} />
            </div>
          </div>
          <div className={el`rate-widget`}>
            <RateWidget ratingMethod={ratingMethod} metadata={metadata} />
          </div>
        </div>
      </InteractableComponent>
    </div>
  );
};
