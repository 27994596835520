import { AuthorizeFrontNotice } from '@/components/AuthorizeFrontNotice';
import { AuthorizeUnderlyingInboxNotice } from '@/components/AuthorizeUnderlyingInboxNotice';
import { Text } from '@shared/components';
import { AuthorizeGmailNotice } from '@shared/components/authorizeInstructions/AuthorizeGmailNotice';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  AuthorizedIntegrationContext,
  AuthorizedIntegrationContextType,
} from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useLazyHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import {
  AuthTokenType,
  FrontProvider,
  IntegrationCredentialsType,
  UsageIndicator,
} from '@shared/generated/graphql';
import { useOrganizationHasCredential } from '@shared/graphql/hooks/useOrganizationHasCredential';
import { useEffect, useState } from 'react';

type FrontAuthorizatedIntegrationProviderProps = {
  children: JSX.Element;
};

export const FrontAuthorizedIntegrationProvider = ({
  children,
}: FrontAuthorizatedIntegrationProviderProps) => {
  const { defaultOrgId, frontEmailProvider } = useMeContext();
  const [emailProviderChecked, setEmailProviderChecked] = useState(false);

  const integrationType = frontEmailProvider
    ? frontEmailProvider === FrontProvider.OUTLOOK
      ? AuthTokenType.MICROSOFT_GRAPH_API
      : AuthTokenType.GMAIL_API
    : '';

  const { getAuthorizedServiceQuery, ...emailProviderAuthorization } =
    useLazyHasAuthorizedIntegration();

  useEffect(() => {
    if (integrationType) {
      getAuthorizedServiceQuery({
        variables: { type: integrationType },
      });
      setEmailProviderChecked(true);
    }
  }, [integrationType]);

  const frontAuthorization = useOrganizationHasCredential({
    organizationId: defaultOrgId ?? '',
    type: IntegrationCredentialsType.FRONT,
    usageIndicator: UsageIndicator.PRODUCTION,
  });

  const refetchBoth = async () => {
    await emailProviderAuthorization.refetch();
    await frontAuthorization.refetch();
  };

  const value: AuthorizedIntegrationContextType = {
    authorizedEmail: emailProviderAuthorization.authorizedEmail,
    authorized:
      emailProviderAuthorization.authorized &&
      (frontAuthorization.hasCredential ?? false),
    loading:
      (emailProviderChecked && emailProviderAuthorization.loading) ||
      frontAuthorization.loading,
    refetch: refetchBoth,
    integrationName: IntegrationCredentialsType.FRONT,
    renderAuthInstructions: () => (
      <AuthorizeFrontIntegrationsNotice
        frontIsAuthorized={frontAuthorization.hasCredential ?? false}
        emailIsAuthorized={emailProviderAuthorization.authorized}
        emailProvider={frontEmailProvider}
        refetch={refetchBoth}
      />
    ),
  };

  return (
    <AuthorizedIntegrationContext.Provider value={value}>
      {children}
    </AuthorizedIntegrationContext.Provider>
  );
};

type AuthorizeFrontIntegrationsNoticeProps = {
  frontIsAuthorized: boolean;
  emailIsAuthorized: boolean;
  emailProvider: FrontProvider | null;
  refetch: () => void;
};
export const AuthorizeFrontIntegrationsNotice = (
  p: AuthorizeFrontIntegrationsNoticeProps
) => {
  if (!p.frontIsAuthorized) return <AuthorizeFrontNotice />;
  if (!p.emailIsAuthorized) {
    if (p.emailProvider === FrontProvider.OUTLOOK) {
      return <AuthorizeUnderlyingInboxNotice />;
    } else if (p.emailProvider === FrontProvider.GMAIL) {
      return <AuthorizeGmailNotice />;
    } else {
      return (
        <div className="p-2">
          <Text type="body-sm" isHeavy className="text-center text-gray-600">
            We cannot determine your organization's email provider. Please
            contact support, support@vooma.ai.
          </Text>
        </div>
      );
    }
  }
  return null;
};
