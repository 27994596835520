import { RatingMethod } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { GroupedRates } from './grouped-rates/GroupedRates';

type RatingErrorProps = {
  quote: Quote;
  ratingMethod: RatingMethod;
  onExpandAnyRateWidget: (ratingMethod: RatingMethod) => void;
  preventRateComparisons: boolean;
};

export function RatingError(p: RatingErrorProps) {
  return (
    // TODO(mike): Reimplement this as a RatingError that shares components with
    // GroupedRates, instead of using the same component
    <GroupedRates
      ratingMethod={p.ratingMethod}
      onExpandAnyRateWidget={p.onExpandAnyRateWidget}
      preventRateComparisons={p.preventRateComparisons}
      rates={[]}
      quote={p.quote}
    />
  );
}
