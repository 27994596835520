import {
  ExternalMessageFragment,
  ExternalMessageType,
  GetConversationExternalMessagesQuery,
} from '@shared/generated/graphql';
import {
  ConversationContributionRole,
  ConversationEvent,
  ConversationEventType,
} from '@shared/types/conversation';

export const conversationEventFromExternalMessageFragment = (
  fragment: ExternalMessageFragment
): ConversationEvent => {
  switch (fragment.type) {
    case ExternalMessageType.VOICE_TRANSCRIPT:
      return {
        id: fragment.id,
        type: ConversationEventType.VOICE_CONTRIBUTION,
        createdAt: new Date(fragment.createdAt),
        role: fragment.content?.role ?? ConversationContributionRole.USER,
        transcript: fragment.content?.transcript ?? '',
      };
    case ExternalMessageType.GMAIL_MESSAGE:
    case ExternalMessageType.MICROSOFT_MESSAGE:
    case ExternalMessageType.VMAIL_MESSAGE:
      return {
        id: fragment.id,
        type: ConversationEventType.EMAIL_CONTRIBUTION,
        createdAt: new Date(fragment.createdAt),
        htmlBody: fragment.htmlBody ?? '',
      };
    case ExternalMessageType.SYSTEM_MESSAGE:
      return {
        id: fragment.id,
        type: ConversationEventType.SYSTEM_MESSAGE,
        createdAt: new Date(fragment.createdAt),
        message: fragment.content ?? '',
      };
    default:
      return {
        id: fragment.id,
        type: ConversationEventType.UNKNOWN,
        createdAt: new Date(fragment.createdAt),
      };
  }
};

export const toConversationEvents = (
  query?: GetConversationExternalMessagesQuery
): ConversationEvent[] => {
  return (
    query?.conversationExternalMessages?.edges.map((e) =>
      conversationEventFromExternalMessageFragment(e.node)
    ) ?? []
  );
};
