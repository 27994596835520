import { StyleProps } from '@shared/utils/shared-types';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { LuMail, LuReplyAll } from 'react-icons/lu';
import { Button } from '../button';
import { CircularProgress } from '../circular-progress';

type MessageActionButtonProps = StyleProps & {
  compact?: boolean;

  messageType?: string;

  draftReply?: () => void;
  draftReplyInProgress?: boolean;
  composeMessage?: () => void;
  composeMessageInProgress?: boolean;
};

export function MessageActionButton({
  className,
  compact,
  draftReply,
  draftReplyInProgress,
  composeMessage,
  composeMessageInProgress,
  messageType = 'email',
}: MessageActionButtonProps) {
  if (isNil(draftReply) && isNil(composeMessage)) {
    return null;
  }

  const divClassName = clsx(
    className,
    compact ? 'flex flex-row' : 'flex flex-row pb-4'
  );
  const buttonClassName = compact
    ? 'flex-grow max-w-full mx-2'
    : 'flex-grow max-w-full mx-4';

  return (
    <div className={divClassName}>
      {isNil(draftReply) ? (
        <Button
          variant="primary"
          className={buttonClassName}
          onPress={composeMessage}
          isDisabled={composeMessageInProgress}
          icon={<LuMail className="text-white" size={24} />}
          tooltip={`Compose ${messageType}`}
        >
          Compose email
        </Button>
      ) : (
        <Button
          variant="primary"
          className={buttonClassName}
          onPress={draftReply}
          isDisabled={draftReplyInProgress}
          tooltip={`This will open as a new ${messageType} draft.`}
          icon={
            draftReplyInProgress ? (
              <CircularProgress className="text-white" size="xs" />
            ) : (
              <LuReplyAll
                className="scale-x-[-1] transform text-white"
                size={24}
              />
            )
          }
        >
          Insert draft response
        </Button>
      )}
    </div>
  );
}

export default MessageActionButton;
