import useAutocomplete, {
  UseAutocompleteProps,
} from '@mui/material/useAutocomplete';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import {
  createCaretDown,
  createCaretUp,
  createCheck,
  createCircleCross,
  svgHelpCircle,
} from '@shared/assets/icons';
import { useOptionalRef } from '@shared/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import { Icon, Text } from '..';

export interface OptionData {
  /**
   * The primary option text.
   * Used to set the value of the input.
   */
  label: string;

  details?: string;
}

export interface AutocompleteBaseProps<
  T extends OptionData,
  Multiple extends boolean | undefined,
> extends Omit<
      UseAutocompleteProps<T, Multiple, false, boolean>,
      'getOptionLabel' | 'getOptionSelected' | 'groupBy'
    >,
    StyleProps {
  /**
   * The size of the autocomplete
   * @default 'medium'
   */
  size?: 'medium' | 'small' | 'xs';

  /**
   * Whether the autocomplete is disabled.
   * @default false
   */
  isDisabled?: boolean;

  /** The placeholder for the input of the autocomplete */
  placeholder?: string;

  /** Set the Autocomplete component to be a combobox */
  combobox?: boolean;

  /** The label for the autocomplete input */
  label?: string;

  /** The informative tooltip content to give context to the user */
  tooltipContent?: string;

  /** The props for the label element. */
  labelProps?: Omit<LabelHTMLAttributes<HTMLLabelElement>, 'id'>;

  /** The props for the input element. */
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'id'>;

  transparentWithoutFocus?: boolean;

  inputWrapperClassName?: string;
  focusedInputWrapperClassName?: string;
  placeholderClassName?: string;
  listboxClassName?: string;

  /**
   *  Show loading symbol in option box for controlled async
   *  autocomplete service loading.
   * @default false
   */
  isLoading?: boolean;

  /**
   * Determines how the element should overflow when it
   * is filled with more selected items than its width can
   * display in one line. It either expands vertically or
   * truncates horizontally. Usually only matters for
   * when autocomplete is set to `multiple`.
   * @default 'resize'
   */
  overflow?: 'resize' | 'truncate';

  /**
   * @default 'valid'
   */
  validationState?: 'valid' | 'invalid';

  /**
   * Decrease font and padding sizes
   */
  compact?: boolean;

  /**
   * Expand to fit width of container.
   */
  expandable?: boolean;
}

const ROOT = makeRootClassName('AutocompleteBase');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'medium',
  isLoading: false,
  isDisabled: false,
  overflow: 'resize',
  validationState: 'valid',
} as const;

const SELECTED_ICON_PATH = createCheck;
const DISMISS_ICON_PATH = createCircleCross;

// helpers

function Chip({
  label,
  onDelete,
  ...props
}: {
  label: string;
  onDelete: (event: any) => void;
}): ReactElement {
  return (
    <div {...props} className={el`chip`}>
      <Text type="body-xs" as="span">
        {label}
      </Text>
      <button tabIndex={-1} onClick={onDelete}>
        <Icon content={DISMISS_ICON_PATH} size="xs" />
      </button>
    </div>
  );
}

// main

function AutocompleteBaseComponent<
  T extends OptionData,
  Multiple extends boolean | undefined,
>(
  props: AutocompleteBaseProps<T, Multiple>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = {
    ...DEFAULT_PROPS,
    ...props,
  };
  const domRef = useOptionalRef(ref);
  const isComboBox = p.combobox && !p.freeSolo;

  // autocomplete behavior

  const getOptionLabel = (option: OptionData | string) => {
    // in case Free Solo is enabled the value for option will be a string,
    // so this checks if it's a string just return the option,
    // otherwise it'll be an object so return the label property
    if (typeof option === 'string') {
      return option;
    }
    return option.label;
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    popupOpen,
    getPopupIndicatorProps,
  } = useAutocomplete({
    // based on MUI documentation for a better combobox-like experience
    // @see https://mui.com/material-ui/react-autocomplete/#creatable
    selectOnFocus: isComboBox,
    clearOnBlur: isComboBox,
    handleHomeEndKeys: isComboBox,
    openOnFocus: isComboBox,
    ...p,
    getOptionLabel,
  });

  const getTextFieldSize = () => {
    switch (p.size) {
      case 'small':
        return 'body-sm';
      case 'xs':
        return 'body-xs';
      default:
        return 'body-md';
    }
  };

  const textFieldSize = getTextFieldSize();
  const isRequired = p.inputProps && p.inputProps.required;
  const { isHovered, hoverProps } = useHover({ isDisabled: p.isDisabled });
  const inputBehaviorProps = mergeProps(hoverProps, {
    placeholder: ' ', // this hack makes :placeholder-shown work
  });

  // Scroll to end of input wrapper in overflow: truncate
  // case so that user can still type in the input.

  const isTruncate = p.overflow === 'truncate';
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTruncate && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTruncate, value]);

  let startAdornment: ReactElement[] = [];

  // hack because TS not recognizing value ** must ** be an
  // array if props.multiple
  if (p.multiple && Array.isArray(value)) {
    if (value && value.length > 0) {
      startAdornment = value.map((option, index): ReactElement => {
        const { onDelete, ...rest } = getTagProps({ index });
        return (
          <Chip
            // If we deleted key property typescript will get mad,
            // if we put it before spreading the props it will also get mad,
            // if we put it after spreading the props it'll overwrite it
            // @typescript-eslint/ban-ts-comment
            // eslint-disable-next-line
            // @ts-ignore
            key={index}
            {...rest}
            onDelete={onDelete}
            label={getOptionLabel(option)}
          />
        );
      });
    }
  }

  return (
    <div
      ref={domRef}
      className={clsx(
        ROOT,
        `size-${p.size}`,
        {
          'has-chips': startAdornment.length > 0,
          'has-tooltip': p.tooltipContent,
          'is-required': isRequired,
          'is-hovered': isHovered,
          'is-disabled': p.isDisabled,
          'is-focused': focused,
          'is-truncate': isTruncate,
          'is-single': !p.multiple,
          'is-valid': p.validationState === 'valid',
          'is-invalid': p.validationState === 'invalid',
          'is-transparent': p.transparentWithoutFocus && !focused,
        },
        p.expandable ? `expandable` : '',
        p.className
      )}
    >
      <div {...getRootProps()}>
        {p.label && (
          <label
            {...p.labelProps}
            {...getInputLabelProps()}
            className={el`label`}
          >
            <div className={el`label-required-indicator`} />
            {p.label}
            {p.tooltipContent && (
              <Icon
                size={p.size}
                content={svgHelpCircle}
                tooltipContent={p.tooltipContent}
                tooltipSide="left"
                tooltipVariant="block"
              />
            )}
          </label>
        )}
        <div
          ref={setAnchorEl}
          {...hoverProps}
          className={clsx(
            el`input-wrapper`,
            { focused },
            focused ? p.focusedInputWrapperClassName : p.inputWrapperClassName
          )}
        >
          {startAdornment}
          <div className={el`textfield-wrapper`}>
            <input
              disabled={p.isDisabled}
              {...p.inputProps}
              {...inputBehaviorProps}
              {...getInputProps()}
              className={el`input`}
              onBlur={(e) => {
                getInputProps().onBlur?.(e);
                p.inputProps?.onBlur?.(e);
              }}
              data-1p-ignore
            />
            {isComboBox &&
              (popupOpen ? (
                <button {...getPopupIndicatorProps()}>
                  <Icon
                    className={el`caret-icon`}
                    content={createCaretUp}
                    size={p.size}
                  />
                </button>
              ) : (
                <button {...getPopupIndicatorProps()}>
                  <Icon
                    className={el`caret-icon`}
                    content={createCaretDown}
                    size={p.size}
                  />
                </button>
              ))}
            {isTruncate && <div ref={scrollRef} className={el`scroll`} />}
            {p.placeholder && (
              <div className={el`placeholder-wrapper`}>
                <span className={clsx(el`placeholder`, p.placeholderClassName)}>
                  {p.placeholder}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* either there're options, or it's a combobox AND the list is open,
       or it's loading */}
      {groupedOptions.length > 0 || (isComboBox && popupOpen) || p.isLoading ? (
        <ul
          {...getListboxProps()}
          className={clsx(el`listbox`, p.listboxClassName)}
        >
          <>
            {p.isLoading && (
              <li className={clsx(el`option`, p.compact && 'COMPACT')}>
                <Text type={textFieldSize}>Loading...</Text>
              </li>
            )}
            <>
              {groupedOptions.length > 0
                ? (groupedOptions as typeof p.options).map((option, index) => (
                    <li
                      {...getOptionProps({ option, index })}
                      className={clsx(el`option`, p.compact && 'COMPACT')}
                      key={index}
                    >
                      <div className={clsx(el`option-label`)}>
                        <Text type={textFieldSize} as="span">
                          {getOptionLabel(option)}
                        </Text>
                        <Icon
                          content={SELECTED_ICON_PATH}
                          size={p.size}
                          className={el`selected-icon`}
                        />
                      </div>
                      {option.details ? (
                        <Text
                          type={textFieldSize}
                          className={clsx(el`option-details`)}
                        >
                          {option.details}
                        </Text>
                      ) : null}
                    </li>
                  ))
                : !p.isLoading && (
                    <li
                      aria-disabled="true"
                      className={clsx(el`option`, p.compact && 'COMPACT')}
                    >
                      <Text type={textFieldSize} as="span">
                        No options
                      </Text>
                    </li>
                  )}
            </>
          </>
        </ul>
      ) : null}
    </div>
  );
}

export const AutocompleteBase = forwardRef(AutocompleteBaseComponent);
/**
 * A base normal text input enhanced by a panel of suggested options.
 * Useful to create ComboBoxes (the value must be selected from a predefined
 * set of options) or MultiSelects.
 *
 * Should be wrapped to create specific more components (i.e.
 * LocationAutocomplete or MakeSelect, etc.)
 */
export default AutocompleteBase;
