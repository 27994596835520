import { Text, Tooltip } from '@shared/components';
import { CarrierRateChip } from '@shared/components/carrier-rate-chip';
import QuoteStatusDropdownChip from '@shared/components/quote-status-dropdown-chip/QuoteStatusDropdownChip';
import { ExternalResourceMinimal } from '@shared/features/externalResource/ExternalResource';
import {
  QuoteBidStatus,
  QuoteHistorySummaryFragment,
  ShipmentCarrierMetadata,
  ShipmentHistorySummaryFragment,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { LaneHistoryFilters } from '@shared/hooks/useLaneHistoryFilters';
import { usePlacesSuggestions } from '@shared/hooks/usePlacesSuggestions';
import { formatTime } from '@shared/plugin/utils/datetime';
import { getElementSeparator } from '@shared/plugin/utils/intersperseElements';
import { Quote } from '@shared/types/quote';
import { makeRootClassName } from '@shared/utils';
import { sumCustomerRate } from 'clerk_common/types';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';
import { MdOpenInNew, MdOutlineFilterAlt } from 'react-icons/md';
import { getQuoteRoute } from '../../utils/getQuoteRoute';
import { FilterChips } from './FilterChips';
import { RadiusEntry } from './RadiusEntry';
import { buildQuotesPageUrl } from './utils/buildQuotesPageUrl';

interface QuoteHistoryRowProps {
  quote: QuoteHistorySummaryFragment;
  navigateToQuote: (quoteId: string) => void;
}

const ROOT = makeRootClassName('QuoteHistoryRow');

const QuoteHistoryRow = (p: QuoteHistoryRowProps) => {
  const sellRate = p.quote.customerRate;
  const createdAt = new Date(p.quote.createdAt);
  const rate = sellRate ? sumCustomerRate(sellRate) : null;
  const { track } = useAnalytics();

  if (!rate) return null;

  const statusChip = (status: QuoteBidStatus) => {
    return (
      <div className={clsx(ROOT)}>
        <QuoteStatusDropdownChip status={status} sellRate={rate} />
      </div>
    );
  };

  return (
    <div
      className="flex cursor-pointer flex-col items-start gap-[4px]"
      onClick={() => {
        track('Clicked Lane History Quote', { quoteId: p.quote.id });
        p.navigateToQuote(p.quote.id);
      }}
    >
      <div className="flex flex-row items-center gap-[4px] text-gray-600">
        {statusChip(p.quote.status)}
        {getElementSeparator({ className: 'text-gray-600' })}
        <Text type="body-sm">{formatTime(createdAt)}</Text>
      </div>
    </div>
  );
};

interface CarrierRateHistoryRowProps {
  carrierRate: CompletedShipmentCarrierMetadata;
}

const CarrierRateHistoryRow = (p: CarrierRateHistoryRowProps) => {
  const { track } = useAnalytics();

  if (!p.carrierRate.pickupAt) return null;
  const pickupAt = new Date(p.carrierRate.pickupAt);
  const carrierName = p.carrierRate.name;
  const rateAmount = p.carrierRate.rateAmount;
  return (
    <div className="flex flex-row items-center gap-[4px] text-gray-600">
      <CarrierRateChip carrierRate={rateAmount} carrierName={carrierName} />
      {getElementSeparator({ className: 'text-gray-600' })}
      <Text type="body-sm">{formatTime(pickupAt)}</Text>
      {getElementSeparator({ className: 'text-gray-600' })}
      <ExternalResourceMinimal
        externalLink={p.carrierRate.externalDeepLink ?? null}
        externalId={p.carrierRate.externalId ?? null}
        handleExternalLinkPress={() => {
          track('Clicked Lane History Shipment', {
            shipmentId: p.carrierRate.shipmentId,
          });
        }}
        handleExternalIdPress={() => {}}
        externalLinkTooltip="View Shipment in TMS"
        copyButtonTooltip={`Copy shipment ID (${p.carrierRate.externalId}) to clipboard`}
      />
    </div>
  );
};

interface QuoteLaneHistoryProps {
  webBaseUrl: string;
  quotes: QuoteHistorySummaryFragment[];
  shipments: ShipmentHistorySummaryFragment[];
  quoteId: string;
  quote: Quote;
  filters: LaneHistoryFilters;
  setFilters: (filters: LaneHistoryFilters) => void;
  navigateToQuote: (quoteId: string) => void;
}

type CompletedShipmentCarrierMetadata = ShipmentCarrierMetadata & {
  pickupAt: string;
  rateAmount: number;

  shipmentId: string;
  externalDeepLink?: string;
  externalId?: string;
};

type QuoteLaneHistoryRowData = {
  type: 'quote' | 'carrierRate';
  data: QuoteHistorySummaryFragment | CompletedShipmentCarrierMetadata;
};

const getQuoteLaneHistoryRowDatas = (
  quotes: QuoteHistorySummaryFragment[],
  shipments: ShipmentHistorySummaryFragment[]
): QuoteLaneHistoryRowData[] => {
  const allRows: QuoteLaneHistoryRowData[] = [];
  allRows.push(
    ...quotes.map((q: QuoteHistorySummaryFragment) => ({
      type: 'quote' as const,
      data: q,
    }))
  );
  for (const s of shipments) {
    allRows.push(
      ...(s.carrierRates
        ?.filter(
          (carrierRate): carrierRate is CompletedShipmentCarrierMetadata =>
            !isNil(carrierRate.pickupAt) && !isNil(carrierRate.rateAmount)
        )
        .map((carrierRate) => ({
          type: 'carrierRate' as const,
          data: {
            ...carrierRate,
            shipmentId: s.id,
            externalDeepLink: s.externalDeepLink ?? undefined,
            externalId: s.externalId ?? undefined,
          },
        })) ?? [])
    );
  }

  const getDate = (row: QuoteLaneHistoryRowData) => {
    if (row.type === 'quote') {
      const quoteData = row.data as QuoteHistorySummaryFragment;
      return new Date(quoteData.createdAt);
    }
    if (row.type === 'carrierRate') {
      const carrierRateData = row.data as CompletedShipmentCarrierMetadata;
      return new Date(carrierRateData.pickupAt);
    }
    throw new Error('Invalid row type');
  };

  const sortedRows = allRows.sort((a, b) => {
    return getDate(b).getTime() - getDate(a).getTime();
  });
  return sortedRows;
};

export const QuoteLaneHistory = (p: QuoteLaneHistoryProps) => {
  const [expanded, setExpanded] = useState(true);
  const { getSuggestions } = usePlacesSuggestions();
  const { track } = useAnalytics();

  const handleToggleHistory = () => {
    const event = expanded ? 'Collapse Lane History' : 'Expand Lane History';
    setExpanded(!expanded);
    track(event, {
      quoteId: p.quoteId,
    });
  };

  const handleOpenLaneHistory = async () => {
    const baseUrl = getQuoteRoute(p.webBaseUrl, p.quoteId);
    const shouldOpenNewWindow = Boolean(p.webBaseUrl);

    const laneHistoryRoute = await buildQuotesPageUrl({
      baseUrl,
      quote: p.quote,
      getSuggestions,
    });

    if (shouldOpenNewWindow) {
      window.open(laneHistoryRoute, '_blank');
    } else {
      window.location.search = laneHistoryRoute;
    }
  };

  const historyRows = getQuoteLaneHistoryRowDatas(p.quotes, p.shipments);

  return !isEmpty(historyRows) ? (
    <div className="flex flex-col p-4">
      <>
        <div
          onClick={handleToggleHistory}
          className="flex cursor-pointer flex-row items-center justify-between text-gray-600"
        >
          <div className="flex flex-row items-center gap-2">
            <Text type="body-md">Lane history</Text>
            <Tooltip
              content={
                p.webBaseUrl
                  ? 'Open Vooma web app filtered to this lane'
                  : 'Filter to this lane'
              }
            >
              <div
                onClick={handleOpenLaneHistory}
                className="text-brand-500 cursor-pointer"
              >
                {p.webBaseUrl ? <MdOpenInNew /> : <MdOutlineFilterAlt />}
              </div>
            </Tooltip>
          </div>
          <Tooltip
            isInstant
            content={expanded ? 'Collapse lane history' : 'Expand lane history'}
          >
            {expanded ? <LuMinus size={16} /> : <LuPlus size={16} />}
          </Tooltip>
        </div>
        {expanded && (
          <div className="mt-2 flex cursor-pointer flex-col gap-2">
            <LaneHistoryDescription filters={p.filters} />
            <RadiusEntry filters={p.filters} setFilters={p.setFilters} />
            <FilterChips filters={p.filters} setFilters={p.setFilters} />
            <div className="flex flex-col gap-3 rounded-lg border border-gray-200 p-3">
              {historyRows.map((row, index) => {
                if (row.type === 'quote') {
                  const quote = row.data as QuoteHistorySummaryFragment;
                  return (
                    <QuoteHistoryRow
                      key={`history-${index}`}
                      quote={quote}
                      navigateToQuote={p.navigateToQuote}
                    />
                  );
                }
                if (row.type === 'carrierRate') {
                  const carrierRate =
                    row.data as CompletedShipmentCarrierMetadata;
                  return (
                    <CarrierRateHistoryRow
                      key={`history-${index}`}
                      carrierRate={carrierRate}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
      </>
    </div>
  ) : null;
};

type LaneHistoryDescriptionProps = {
  filters: LaneHistoryFilters;
};

function LaneHistoryDescription(p: LaneHistoryDescriptionProps) {
  const originRadius = p.filters.laneFilter?.origin?.radiusMiles;
  const originRadiusString = originRadius ? `${originRadius} mi` : '';
  const destinationRadius = p.filters.laneFilter?.destination?.radiusMiles;
  const destinationRadiusString = destinationRadius
    ? `${destinationRadius} mi`
    : '';
  const hasRadius =
    !isEmpty(originRadiusString) && !isEmpty(destinationRadiusString);
  const sameRadius = originRadius === destinationRadius;

  let str = 'Recent quotes and shipments';
  if (hasRadius) {
    if (sameRadius) {
      str += ` within ${originRadiusString} of origin and destination`;
    } else {
      str += ` within ${originRadiusString} of origin, ${destinationRadiusString} of destination`;
    }
  }

  return (
    <div className="flex cursor-default flex-row items-center gap-2">
      <Text type="body-sm" className="text-gray-500">
        {str}
      </Text>
    </div>
  );
}
