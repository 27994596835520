export enum TokenizableEntityType {
  QUOTE_ID = 'QUOTE_ID',
  ORDER_ID = 'ORDER_ID',
  JOB_ID = 'JOB_ID',
  FOR_UNIT_TESTS = 'FOR_UNIT_TESTS',
}

const OBSCURE_SEPARATOR = '##VOOMA##ENTITY##ID##';

const DIVIDER = '+';

export function getTokenizedString(
  entityType: TokenizableEntityType,
  value: string
) {
  switch (entityType) {
    case TokenizableEntityType.QUOTE_ID:
    case TokenizableEntityType.ORDER_ID:
    case TokenizableEntityType.JOB_ID:
      return `${OBSCURE_SEPARATOR}${entityType}${DIVIDER}${value}${OBSCURE_SEPARATOR}`;
    default:
      throw new Error('Token not supported');
  }
}

export function extractTokenizedValueFromEmailBody(
  entityType: TokenizableEntityType,
  emailBody: string
) {
  const pattern = `${OBSCURE_SEPARATOR}.*?\\+.*?${OBSCURE_SEPARATOR}`;
  const separatorPattern = `${OBSCURE_SEPARATOR}`;

  const regex = new RegExp(pattern, 'g');
  const separatorRegex = new RegExp(separatorPattern, 'g');

  // TODO(Martin): for now, return first match (we only put one in emails).
  // We can easily extend this to more than one token in body
  const tokenizedString = Array.from(emailBody.matchAll(regex)).map(
    (match) => match[0]
  )[0];

  if (!tokenizedString) return null;

  const [eType, value] = tokenizedString
    .replace(separatorRegex, '')
    .split(DIVIDER);
  switch (entityType) {
    case eType:
      return value;
    default:
      null;
  }
}
