import { isNil } from 'lodash';

export type AutoReplySettingsForm = {
  draftingInstructions?: string;
  whenToRespond?: string[];
};

export const getDefaultFormValues = (
  autoReplySettings?: AutoReplySettingsForm | null
) => {
  if (!isNil(autoReplySettings)) {
    return autoReplySettings;
  }

  return {
    draftingInstructions: undefined,
    whenToRespond: undefined,
  };
};
