import { format, differenceInMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export function formatElapsedTime(
  conversationStartTime: Date,
  contributionTime: Date
): string {
  const elapsedMs =
    contributionTime.getTime() - conversationStartTime.getTime();
  if (differenceInMinutes(contributionTime, conversationStartTime) < 60) {
    return format(elapsedMs, 'm:ss');
  }
  return formatAbsoluteTime(contributionTime);
}

export function formatAbsoluteTime(time: Date): string {
  const now = new Date();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (isSameDay(now, time)) {
    return formatInTimeZone(time, timezone, 'h:mm a zzz');
  }
  return formatInTimeZone(time, timezone, 'MMM d, h:mm a zzz');
}

function isSameDay(now: Date, time: Date): boolean {
  return (
    formatInTimeZone(now, 'UTC', 'yyyy-MM-dd') ===
    formatInTimeZone(time, 'UTC', 'yyyy-MM-dd')
  );
}
