import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { QuoteBidStatus } from '@shared/generated/graphql';
import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import clsx from 'clsx';
import { ReactElement, useMemo, useState } from 'react';
import {
  GoCheckCircleFill,
  GoChevronDown,
  GoXCircleFill,
} from 'react-icons/go';

import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import { InteractableComponent } from '../interactable-component';
import { Text } from '../text';

export const QUOTE_STATUS_OPTIONS = [
  { label: 'New', value: QuoteBidStatus.NEW },
  { label: 'Quoted', value: QuoteBidStatus.QUOTED },
  { label: 'Won', value: QuoteBidStatus.WON },
  { label: 'Lost', value: QuoteBidStatus.LOST },
];

export const getQuoteStatusChipIcon = (status: QuoteBidStatus) => {
  switch (status) {
    case QuoteBidStatus.WON:
      return <GoCheckCircleFill />;
    case QuoteBidStatus.LOST:
      return <GoXCircleFill />;
    default:
      return null;
  }
};

export type QuoteStatusDropdownChipProps = StyleProps & {
  status: QuoteBidStatus;
  isDisabled?: boolean;
  handleSelect?: (value: QuoteBidStatus) => void;
  sellRate?: number;
};

const ROOT = makeRootClassName('QuoteStatusDropdownChip');
const el = makeElementClassNameFactory(ROOT);

function QuoteStatusDropdownChip(
  p: QuoteStatusDropdownChipProps
): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = useMemo(() => {
    return QUOTE_STATUS_OPTIONS.find((option) => option.value === p.status);
  }, [p.status]);
  const defaultLabel = 'Select option...';
  const rateStr = p.sellRate
    ? usdFormatterNoCents.format(p.sellRate)
    : undefined;
  const icon = getQuoteStatusChipIcon(p.status);

  return (
    <RadixDropdown.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <RadixDropdown.Trigger className="focus:outline-none">
        <InteractableComponent
          className={clsx(ROOT, p.className, `variant-${p.status}`, {
            'is-disabled': p.isDisabled,
          })}
          onPress={() => setIsOpen(!isOpen)}
          isDisabled={!p.handleSelect}
        >
          <div className="flex flex-row items-center gap-1 text-gray-700">
            {icon && (
              <div className={clsx(el`chip-icon`, `variant-${p.status}`)}>
                {icon}
              </div>
            )}
            <Text className={el`chip-text`} type="body-xs">
              {selectedOption?.label ?? defaultLabel}
            </Text>
            {rateStr && <Text type="body-xs">{'•'}</Text>}
            {rateStr && <Text type="body-xs">{rateStr}</Text>}
            <GoChevronDown size={12} className="flex-shrink-0" />
          </div>
        </InteractableComponent>
      </RadixDropdown.Trigger>
      <RadixDropdown.Content align="end" className={el`menu no-scrollbar`}>
        {QUOTE_STATUS_OPTIONS.map((option) => (
          <RadixDropdown.Item
            key={option.value}
            className={
              'border-none outline-none hover:outline-none focus:outline-none'
            }
            onClick={() => {
              p.handleSelect?.(option.value);
            }}
          >
            <div className={el`dropdown-menu-item`}>
              <Text type="body-sm" className={'m-2 p-[8px]'}>
                {option.label}
              </Text>
            </div>
          </RadixDropdown.Item>
        ))}
      </RadixDropdown.Content>
    </RadixDropdown.Root>
  );
}

export default QuoteStatusDropdownChip;
