import {
  LaneFilter,
  ShipmentHistorySummaryFragment,
  useGetLaneShipmentsQuery,
} from '@shared/generated/graphql';

type ShipmentFilters = {
  equipmentTypes?: string[];
  laneFilter?: LaneFilter;
};

export const useGetLaneShipments = (shipmentFilters?: ShipmentFilters) => {
  const { data, loading, error } = useGetLaneShipmentsQuery({
    variables: {
      input: {
        equipmentTypes: shipmentFilters?.equipmentTypes,
        laneFilter: shipmentFilters?.laneFilter,
      },
    },
  });

  const shipments =
    data?.shipments.edges.map((edge) => edge.node) ??
    ([] as ShipmentHistorySummaryFragment[]);

  return {
    shipments,
    loading,
    error,
  };
};
