import { z } from 'zod';

export const AutoReplySettingsSchema = z
  .object({
    whenToRespond: z
      .array(z.string())
      .optional()
      .describe('List of go/no-go conditions for auto-reply'),
    draftingInstructions: z
      .string()
      .optional()
      .describe('AI instructions for drafting quote responses'),
  })
  .strict();

export type AutoReplySettings = z.infer<typeof AutoReplySettingsSchema>;

export const OriginatorSettingsSchema = z
  .object({
    autoReply: AutoReplySettingsSchema.optional(),
  })
  .strict();

export type OriginatorSettings = z.infer<typeof OriginatorSettingsSchema>;
