import { useHover } from '@react-aria/interactions';
import { Button } from '@shared/components/button';
import { ActionTimelineEvent } from '@shared/components/events-timeline/components/action-timeline-event';
import { FlowIcon } from '@shared/components/flow-icon/FlowIcon';
import { InlineDateField } from '@shared/components/inline-date-field/InlineDateField';
import { InlineTimeField } from '@shared/components/inline-time-field/InlineTimeField';
import { useQuoteFormAdaptorContext } from '@shared/contexts/QuoteFormAdaptorContext';
import { getElementSeparator } from '@shared/plugin/utils/intersperseElements';
import {
  getIsRequiredField,
  getValidateAddress,
} from '@shared/utils/forms/formAdaptor';
import { QuoteFormValues, Stop } from '@shared/utils/forms/types';
import { StopType } from 'clerk_common/types/stop';
import { Trash2 } from 'react-feather';
import { Path } from 'react-hook-form';
import { AddressSearch } from './AddressSearch';

const getDisplayStopType = (stopType: StopType, i: number, length: number) => {
  if (stopType === 'DELIVERY' && i === length - 1) {
    return 'DELIVERY';
  }

  return 'PICKUP';
};

type RouteStopProps = {
  index: number;
  stopType: StopType;
  displayStopType: 'PICKUP' | 'DELIVERY';
  removeStop: () => void;
  el: (strings: TemplateStringsArray) => string;
  allowCustomAddress?: boolean;
};

const RouteStop = (p: RouteStopProps) => {
  const { index, stopType, displayStopType, el } = p;

  const { save, integrationConfigs } = useQuoteFormAdaptorContext();

  const { isHovered, hoverProps } = useHover({});

  const dateName: Path<QuoteFormValues> =
    stopType === 'PICKUP' ? 'pickup.date' : `stops.${index}.date`;
  const isDateRequired = getIsRequiredField(dateName, integrationConfigs);

  const timeName: Path<QuoteFormValues> =
    stopType === 'PICKUP' ? 'pickup.time' : `stops.${index}.time`;
  const isTimeRequired = getIsRequiredField(timeName, integrationConfigs);

  const locationName: Path<QuoteFormValues> =
    stopType === 'PICKUP' ? 'pickup.location' : `stops.${index}.location`;

  return (
    <div {...hoverProps}>
      <ActionTimelineEvent type={displayStopType}>
        <div className={el`route-step`}>
          <div className={el`datetime-row`}>
            <div className={el`datetime-wrapper`}>
              <InlineDateField<QuoteFormValues>
                save={save}
                name={dateName}
                required={isDateRequired}
              />
              <FlowIcon fieldPath={dateName} type="DATE" />
              {getElementSeparator({ className: el`separator` })}
              <InlineTimeField<QuoteFormValues>
                save={save}
                name={timeName}
                required={isTimeRequired}
              />
              <FlowIcon fieldPath={timeName} />
            </div>
            {stopType == 'DELIVERY' && isHovered && (
              <Button
                isGhost
                size="xs"
                tooltip="Remove stop"
                icon={<Trash2 className="text-gray-400" size={12} />}
                onPress={p.removeStop}
              />
            )}
          </div>
          <AddressSearch<QuoteFormValues>
            save={save}
            name={locationName}
            required
            validateAddress={getValidateAddress(
              `${locationName}.address`,
              integrationConfigs
            )}
            allowCustomAddress={p.allowCustomAddress}
          />
        </div>
      </ActionTimelineEvent>
    </div>
  );
};

interface RouteSegmentProps {
  stops: Stop[];
  stopType: StopType;
  removeStop: (idx: number) => void;
  el: (strings: TemplateStringsArray) => string;
  allowCustomAddress?: boolean;
}

export const RouteSegment = ({
  stops,
  stopType,
  removeStop,
  el,
  allowCustomAddress,
}: RouteSegmentProps) => {
  return (
    <>
      {stops.map((_, i) => {
        const displayStopType = getDisplayStopType(stopType, i, stops.length);
        return (
          <RouteStop
            index={i}
            key={`route-segment-${i}`}
            stopType={stopType}
            displayStopType={displayStopType}
            removeStop={() => removeStop(i)}
            el={el}
            allowCustomAddress={allowCustomAddress}
          />
        );
      })}
    </>
  );
};
