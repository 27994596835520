import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  MeDocument,
  useUpdateUserSettingsMutation,
} from '@shared/generated/graphql';
import { AutoReplySettings } from '../AutoReplySettings';
import { AutoReplySettingsForm } from '../types';

type UserAutoReplySettingsProps = {
  quoteIds: string[];
  handleClose: () => void;
};

export const UserAutoReplySettings = ({
  quoteIds,
  handleClose,
}: UserAutoReplySettingsProps) => {
  const { me } = useMeContext();
  const [updateUserSettings, { loading }] = useUpdateUserSettingsMutation();
  const { sendToast } = useToast();

  const handleSubmit = async (
    autoReply: AutoReplySettingsForm,
    shouldClose?: boolean
  ) => {
    if (!me?.id) {
      sendToast('Unexpected error updating settings', {
        variant: 'error',
      });
      return;
    }

    const { __typename, ...otherSettings } = me.settings || {};
    const settings = {
      ...otherSettings,
      autoReply,
    };

    try {
      const res = await updateUserSettings({
        variables: {
          input: {
            id: me?.id,
            settings,
          },
        },
        refetchQueries: [MeDocument],
      });

      if (res.data?.updateUserSettings.success) {
        sendToast('Settings updated', {
          variant: 'success',
        });
        shouldClose && handleClose();
        return;
      }
    } catch (err) {
      console.error(err);
    }

    sendToast('Error updating settings', {
      variant: 'error',
    });
  };

  return (
    <AutoReplySettings
      quoteIds={quoteIds}
      onSubmit={handleSubmit}
      loadingSubmit={loading}
      autoReplySettings={me?.settings?.autoReply}
      featureDescription="Tell Vooma how you would like to respond. You can describe your ideal response format, provide a template, or give an example. Settings will apply to all of your quotes."
    />
  );
};
