function calculateMarkupFromMargin(margin: number, buyRate: number): number {
  // Validate inputs
  if (margin <= 0 || margin >= 100) {
    throw new Error('Margin must be greater than 0% and less than 100%');
  }
  if (buyRate <= 0) {
    throw new Error('Buy rate must be greater than 0');
  }

  // Calculate sell rate from margin and buy rate
  const sellRate = buyRate / (1 - margin / 100);

  // Calculate markup
  const markup = ((sellRate - buyRate) / buyRate) * 100;

  return Math.round(markup);
}

export function getMarkupFromMargin(
  margin?: number,
  buyRate?: number
): string | undefined {
  if (!margin || !buyRate) return undefined;

  try {
    return `${calculateMarkupFromMargin(margin, buyRate)}%`;
  } catch {
    return;
  }
}
