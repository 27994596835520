import { Modal } from '@shared/components';
import { ResponseDraftContext } from '@shared/contexts/ResponseDraftContext';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useContext, useEffect } from 'react';
import { UserAutoReplySettings } from '../UserAutoReplySettings/UserAutoReplySettings';

const ROOT = makeRootClassName('AutoReplySettingsModal');
const el = makeElementClassNameFactory(ROOT);

export enum AutoReplySettingsLevel {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  TEAM = 'TEAM',
  ORIGINATOR = 'ORIGINATOR',
}

type AutoReplySettingsModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  quoteIds: string[];
  settingsLevel: AutoReplySettingsLevel;
};

const getSettingsLevelLabel = (settingsLevel: AutoReplySettingsLevel) => {
  switch (settingsLevel) {
    case AutoReplySettingsLevel.USER:
      return 'User';
    case AutoReplySettingsLevel.ORGANIZATION:
      return 'Organization';
    case AutoReplySettingsLevel.TEAM:
      return 'Team';
    case AutoReplySettingsLevel.ORIGINATOR:
      return 'Shipper';
  }
};

export const AutoReplySettingsModal = ({
  open,
  setOpen,
  quoteIds,
  settingsLevel,
}: AutoReplySettingsModalProps) => {
  const { refetchDraft } = useContext(ResponseDraftContext);

  useEffect(() => {
    if (!open) {
      refetchDraft?.();
    }
  }, [open]);

  const settingsContainer = () => {
    switch (settingsLevel) {
      case AutoReplySettingsLevel.USER:
        return (
          <UserAutoReplySettings
            quoteIds={quoteIds}
            handleClose={() => setOpen(false)}
          />
        );
      case AutoReplySettingsLevel.ORGANIZATION:
        throw new Error('Organization settings not implemented');
      case AutoReplySettingsLevel.TEAM:
        throw new Error('Team settings not implemented');
      case AutoReplySettingsLevel.ORIGINATOR:
        throw new Error('Originator settings not implemented');
      default:
        throw new Error('Invalid settings level');
    }
  };

  return (
    <Modal.Root className={ROOT} open={open} onOpenChange={setOpen}>
      <Modal.Content size="responsive-takeover">
        <Modal.Header
          className={el`header`}
          title={`${getSettingsLevelLabel(
            settingsLevel
          )} reply drafting settings`}
          hasCloseButton
          onClose={() => setOpen(false)}
        />
        {settingsContainer()}
      </Modal.Content>
    </Modal.Root>
  );
};
