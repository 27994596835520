import { capitalize } from 'lodash';

export const STANDARD_REFERENCE_TYPES = [
  'ORDER_REFERENCE',
  'PURCHASE_ORDER',
  'BILL_OF_LADING',
  'INVOICE',
  'SHIPMENT',
  'SALES_ORDER',
  'TRACKING',
  'DELIVERY',
  'CUSTOMER_REFERENCE',
  'LOAD',
  'CONTAINER',
  'MASTER_BILL_OF_LADING',
  'BOOKING',
  'SEAL',
  'PICKUP',
  'APPOINTMENT',
  'PRO',
  'CONSIGNEE_REFERENCE',
  'OTHER',
] as const;
type StandardReferenceTuple = typeof STANDARD_REFERENCE_TYPES;
export type StandardReferenceType = StandardReferenceTuple[number];

export const STANDARD_EQUIPMENT_LENGTHS = [
  12, 20, 24, 26, 28, 32, 36, 40, 42, 43, 45, 48, 53, 60, 65, 70,
] as const;
type StandardEquipmentLengthTuple = typeof STANDARD_EQUIPMENT_LENGTHS;
export type StandardFreightOrderEquipmentLength =
  StandardEquipmentLengthTuple[number];

export const STANDARD_EQUIPMENT_TYPES = [
  'BOX_TRUCK',
  'CARGO_VAN',
  'CONESTOGA',
  'CONTAINER',
  'DOUBLE_DROP',
  'DRY_VAN',
  'FLATBED',
  'HOT_SHOT',
  'LOW_BOY',
  'POWER_ONLY',
  'REEFER',
  'REMOVABLE_GOOSENECK',
  'SPRINTER_VAN',
  'STEP_DECK',
  'STRAIGHT_TRUCK',
  'AUTO_CARRIER',
  'OTHER',
] as const;
type StandardEquipmentTuple = typeof STANDARD_EQUIPMENT_TYPES;
export type StandardFreightOrderEquipmentType = StandardEquipmentTuple[number];

export const AUCTION_TYPES = ['AUCTION', 'NON_AUCTION'] as const;
type AuctionTuple = typeof AUCTION_TYPES;
export type AuctionType = AuctionTuple[number];

export const STANDARD_TRANSACTION_PURPOSES = [
  'ORIGINAL',
  'UPDATE',
  'CANCELLATION',
] as const;
type StandardTransactionPurposeTuple = typeof STANDARD_TRANSACTION_PURPOSES;
export type StandardTransactionPurpose =
  StandardTransactionPurposeTuple[number];

export const STANDARD_SHIPPING_UNITS = [
  'BAGS',
  'BALES',
  'BOTTLES',
  'BOXES',
  'BUNDLES',
  'CARTONS',
  'CASES',
  'CRATES',
  'DRUMS',
  'PACKAGES',
  'PAILS',
  'PALLETS',
  'PIECES',
  'ROLLS',
  'SKIDS',
  'TRUCKLOADS',
  'TUBES',
  'UNITS',
  'VEHICLES',
] as const;
type StandardShippingUnitsTuple = typeof STANDARD_SHIPPING_UNITS;
export type StandardShippingUnit = StandardShippingUnitsTuple[number];

export const STANDARD_FREIGHT_MODES = [
  'TRUCKLOAD',
  'DRAYAGE',
  'PARTIAL_TRUCKLOAD',
  'LTL',
] as const;
type StandardFreightModeTuple = typeof STANDARD_FREIGHT_MODES;
export type StandardFreightMode = StandardFreightModeTuple[number];

export const DRAYAGE_SUBTYPES = ['IMPORT', 'EXPORT'] as const;
type DrayageSubtypeTuple = typeof DRAYAGE_SUBTYPES;
export type DrayageSubtype = DrayageSubtypeTuple[number];

export const STANDARD_LINE_ITEMS = [
  'FREIGHT_FLAT',
  'FREIGHT_PER_MILE',
  // TODO(max): FUEL is used for EDI. Usages of FUEL_FLAT should be converted over to FUEL in the future (legacy from Turvo names).
  'FUEL',
  'FUEL_FLAT',
  'FUEL_PER_MILE',
  'FREIGHT_PER_CONTAINER',
  'CHASSIS',
  'OVERWEIGHT',
  'PRE_PULL',
  'STORAGE',
  'DETENTION',
  'TMF_PIER_PASS',
  'PORT_FEE',
  'REDELIVERY',
  'EXTRA_MILES',
  'TRANSLOAD',
  'ESCORT',
  'EXPEDITED',
  'CHASSIS_SPLIT',
  'OTHER',
] as const;
type StandardLineItemTuple = typeof STANDARD_LINE_ITEMS;
export type StandardLineItemType = StandardLineItemTuple[number];

export const STANDARD_ACCESSORIALS = [
  'LIFTGATE',
  'TARPING',
  'HEADACHE_RACK',
  'COIL_RACK',
  'PIPE_STAKES',
  'GPS',
  'OVER_DIMENSION',
  'NON_ROUTED_SHIPMENT',
  'DELIVERY_BY_APPOINTMENT',
  'BLIND_SHIPMENT',
  'DRIVER_ASSISTANCE',
  'SCALE',
  'TEMPERATURE_CONTROLLED',
  'STRAPS',
  'HAZMAT',
  'TEAM',
  'DRAYAGE',
  'GUARANTEED_DELIVERY',
  'TRANSLOADING',
  'PPE',
  'CHAINS_AND_BINDERS',
  'DUNNAGE',
  'BACKHAUL',
  'DOCK_HEIGHT',
  'FOOD_GRADE',
  'TWIC',
  'LIFTGATE_DELIVERY',
  'LIFTGATE_PICKUP',
  'INSIDE_PICKUP',
  'INSIDE_DELIVERY',
  'LIMITED_ACCESS',
  'NOTIFY_SHIPPER',
  'NOTIFY_CONSIGNEE',
] as const;
type StandardAccessorialTuple = typeof STANDARD_ACCESSORIALS;
export type StandardAccessorial = StandardAccessorialTuple[number];

export const FREIGHT_CLASSES = [
  '50',
  '55',
  '60',
  '65',
  '70',
  '77',
  '77.5',
  '85',
  '92',
  '92.5',
  '100',
  '110',
  '125',
  '150',
  '175',
  '200',
  '250',
  '300',
  '400',
  '500',
] as const;
type FreightClassTuple = typeof FREIGHT_CLASSES;
export type FreightClass = FreightClassTuple[number];

export const STANDARD_MILEAGE_UNITS = ['MI', 'KM'] as const;
type StandardMileageUnitsTuple = typeof STANDARD_MILEAGE_UNITS;
export type StandardMileageUnit = StandardMileageUnitsTuple[number];

export const STANDARD_DIMENSION_UNITS = ['IN', 'CM', 'FT'] as const;
type StandardDimensionUnitsTuple = typeof STANDARD_DIMENSION_UNITS;
export type StandardDimensionUnit = StandardDimensionUnitsTuple[number];

export const STANDARD_TEMPERATURE_UNITS = ['F', 'C'] as const;
type StandardTemperatureUnitsTuple = typeof STANDARD_TEMPERATURE_UNITS;
export type StandardTemperatureUnit = StandardTemperatureUnitsTuple[number];

export const STANDARD_WEIGHT_UNITS = ['LBS', 'KGS'] as const;
type StandardWeightUnitsTuple = typeof STANDARD_WEIGHT_UNITS;
export type StandardWeightUnit = StandardWeightUnitsTuple[number];

export const STOP_TYPES = ['PICKUP', 'DELIVERY'] as const;
type StopTypeTuple = typeof STOP_TYPES;
export type StopType = StopTypeTuple[number];

export const STANDARD_TIME_RANGE_TYPES = [
  'FACILITY_HOURS',
  'APPOINTMENT',
] as const;
type StandardTimeRangeTypeTuple = typeof STANDARD_TIME_RANGE_TYPES;
export type StandardTimeRangeType = StandardTimeRangeTypeTuple[number];

export const STANDARD_PAYMENT_TERMS = [
  'PREPAID',
  'COLLECT',
  'THIRD_PARTY',
] as const;
type StandardPaymentTermsTuple = typeof STANDARD_PAYMENT_TERMS;
export type StandardPaymentTerms = StandardPaymentTermsTuple[number];

export const HAZMAT_PACKING_GROUPS = ['I', 'II', 'III'] as const;
type HazmatPackingGroupTuple = typeof HAZMAT_PACKING_GROUPS;
export type HazmatPackingGroup = HazmatPackingGroupTuple[number];

export const HAZMAT_CLASSES = [
  '1',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '2',
  '2.1',
  '2.2',
  '2.3',
  '3',
  '4',
  '4.1',
  '4.2',
  '4.3',
  '5',
  '5.1',
  '5.2',
  '6',
  '6.1',
  '6.2',
  '7',
  '8',
  '9',
] as const;
type HazmatClassTuple = typeof HAZMAT_CLASSES;
export type HazmatClass = HazmatClassTuple[number];

export const HAZMAT_CLASS_QUALIFIERS = ['P', 'S'] as const;
type HazmatClassQualifierTuple = typeof HAZMAT_CLASS_QUALIFIERS;
export type HazmatClassQualifier = HazmatClassQualifierTuple[number];

export const DECIDE_IGNORE_MESSAGE_CLASSES = ['ATTEND', 'IGNORE'];
type DecideIgnoreMessageClassTuple = typeof DECIDE_IGNORE_MESSAGE_CLASSES;
export type DecideIgnoreMessageClass = DecideIgnoreMessageClassTuple[number];

export const DECIDE_MESSAGE_ACTION_CLASSES = [
  'IGNORE',
  // Umbrella class for freight-related workflows that may be supported by
  // Vooma products in the future, but are currently not.
  'NOT_CURRENTLY_SUPPORTED',
  'BUILD_QUOTE',
  'BUILD_ORDER',
  'BUILD_QUOTE_AND_ORDER',
  'UPDATE_ENTITIES',
];
type DecideMessageActionClassTuple = typeof DECIDE_MESSAGE_ACTION_CLASSES;
export type DecideMessageActionClass = DecideMessageActionClassTuple[number];

export const FREIGHT_ENTITY_TYPES = [
  // Spot quote lifecycle
  'SPOT_QUOTE_REQUEST',
  'SPOT_QUOTE_ACCEPTANCE',
  'SPOT_QUOTE_REJECTION',
  'SPOT_QUOTE_UPDATE',
  'SPOT_QUOTE_NEGOTIATION',

  // Contract rate lifecycle
  'CONTRACT_RATE_REQUEST',
  'CONTRACT_RATE_NEGOTIATION',
  'CONTRACT_RATE_ACCEPTANCE',
  'CONTRACT_RATE_REJECTION',

  // Auction lifecycle
  'NEW_AUCTION',
  'AUCTION_WON',
  'AUCTION_LOST',
  'AUCTION_DETAILS_UPDATED',

  // Quote responses
  'REPLY_TO_QUOTE_REQUEST',
  'REQUEST_FOR_DETAILS',

  // Order lifecycle
  'NEW_ORDER',
  'ORDER_UPDATE',
  'ORDER_CANCELLATION',

  // Appointment lifecycle
  'APPOINTMENT_CONFIRMED',
  'APPOINTMENT_UPDATE',
  'APPOINTMENT_CANCELLATION',
  'REQUEST_FOR_APPOINTMENT',

  // Carrier booking lifecycle
  'CARRIER_BOOKING_REQUEST',
  'CARRIER_BOOKING_CONFIRMED',
  'CARRIER_BOOKING_EXCEPTION',

  // Carrier quoting lifecycle
  'CARRIER_QUOTE_REQUEST',
  'CARRIER_QUOTE_ACCEPTANCE',
  'CARRIER_QUOTE_REJECTION',
  'CARRIER_QUOTE_UPDATE',
  'CARRIER_QUOTE_NEGOTIATION',
  'CARRIER_QUOTE_EXCEPTION',

  // Carrier availability notices
  'TRUCK_AVAILABILITY_NOTICE',

  // Shipment management lifecycle
  'LOAD_LOCATION_INQUIRY',
  'LOAD_ETA_INQUIRY',
  'LOAD_EXCEPTION',
  'LOAD_STATUS_UPDATE',
  'LOAD_DETAILS_REQUEST',
  'LOAD_DETAILS_RESPONSE',

  // Driver management lifecycle
  'DRIVER_LOCATION_UPDATE',
  'DRIVER_AVAILABILITY_UPDATE',
  'DRIVER_LOAD_INQUIRY',
  'DRIVER_EXCEPTION',

  // Invoice lifecycle
  'INVOICE_REQUEST',
  'INVOICE_SENT',
  'INVOICE_RECEIVED',
  'INVOICE_CANCELLATION',
  'INVOICE_EXCEPTION',

  // General communication.
  'ACKNOWLEDGEMENT',

  // Additional exception management.
  'OUT_OF_OFFICE',

  // Other
  'UNKNOWN',
  // For when it's clear it's a new general type of class, but the class isn't
  // included yet in the labeling tool.
  'MISSING_CLASS',
  // For when there's not enough information in the current labeling tool to
  // make a determination on what the class should be.
  'NOT_ENOUGH_INFORMATION',
  // For when the message is unrelated to Vooma's current or future product offerings.
  'NOT_APPLICABLE',
] as const;
type FreightEntityTypesTuple = typeof FREIGHT_ENTITY_TYPES;
export type FreightEntityType = FreightEntityTypesTuple[number];

export const FREIGHT_ENTITY_MODES = ['LTL', 'FTL'];
type FreightEntityModesTuple = typeof FREIGHT_ENTITY_MODES;
export type FreightEntityMode = FreightEntityModesTuple[number];

export const FREIGHT_MESSAGE_SENDER_TYPES = ['AUTOMATED', 'HUMAN'];
type FreightMessageSenderTypesTuple = typeof FREIGHT_MESSAGE_SENDER_TYPES;
export type FreightMessageSenderType = FreightMessageSenderTypesTuple[number];

export const QUOTE_RESPONSE_CLASSES: FreightEntityType[] = [
  'REPLY_TO_QUOTE_REQUEST',
  'REQUEST_FOR_DETAILS',
];

export const STOP_SCHEDULING_TYPES = [
  'FIRST_COME_FIRST_SERVED',
  'APPOINTMENT',
] as const;
type StopSchedulingTypesTuple = typeof STOP_SCHEDULING_TYPES;
export type StopSchedulingType = StopSchedulingTypesTuple[number];

export const DAY_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const;
type DayOfWeekTuple = typeof DAY_OF_WEEK;
export type DayOfWeek = DayOfWeekTuple[number];

const knownAcronyms = ['GPS', 'LTL'];

export function enumToString(e: string) {
  if (knownAcronyms.includes(e)) {
    return e;
  }
  const spaced = e.replace(/_/g, ' ');
  const words = spaced.split(' ');
  const capitalized = words.map((w) => capitalize(w));
  return capitalized.join(' ');
}
