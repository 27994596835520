import {
  GeocodeResult,
  PlaceAutocompleteResult,
} from '@googlemaps/google-maps-services-js';
import axios from 'axios';

import {
  ApiReqType,
  useAPIContext,
} from '@shared/contexts/pluginContexts/APIContext';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import {
  GEOCODE_BY_PLACE_ID_URL,
  PLACE_SUGGESTIONS_URL,
} from '../constants/placesProxyUrls';

export type PlaceSuggestionResults = PlaceAutocompleteResult[];
export type PlaceGeocodeResults = GeocodeResult[];

export type PlaceSuggestion = {
  results: PlaceSuggestionResults;
  setSearchTerm: (searchTerm: string) => void;
  geocodeByPlaceId: (id: string) => Promise<PlaceGeocodeResults | undefined>;
  loading: boolean;
  onSelectionMade: () => void;
  getSuggestions: (searchTerm: string) => Promise<PlaceSuggestionResults>;
};

export function usePlacesSuggestions(): PlaceSuggestion {
  const { url, headers } = useAPIContext(ApiReqType.PLACES);
  const [suggestions, setSuggestions] = React.useState<
    PlaceAutocompleteResult[]
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = debounce(setSearchTerm, 75);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sessionToken, setSessionToken] = React.useState<string>(
    crypto.randomUUID()
  );

  async function geocodeByPlaceId(id: string) {
    if (id) {
      const res = await axios.get(
        `${url + GEOCODE_BY_PLACE_ID_URL}/${encodeURIComponent(id)}`,
        { headers }
      );
      return res.data?.results as GeocodeResult[];
    }
  }

  function onSelectionMade() {
    setSearchTerm('');
    setSessionToken(crypto.randomUUID());
  }

  useEffect(() => {
    async function fetchSuggestions() {
      if (searchTerm) {
        setLoading(true);
        const endPoint = `${url + PLACE_SUGGESTIONS_URL}/${encodeURIComponent(
          searchTerm
        )}/${sessionToken}`;

        const res = await axios.get(endPoint, { headers });
        setLoading(false);
        setSuggestions(res.data.predictions);
      }
    }
    fetchSuggestions();
  }, [searchTerm]);

  const getSuggestions = async (searchTerm: string) => {
    const endPoint = `${url + PLACE_SUGGESTIONS_URL}/${encodeURIComponent(
      searchTerm
    )}/${sessionToken}`;
    const res = await axios.get(endPoint, { headers });
    return res.data.predictions;
  };

  return {
    results: suggestions,
    setSearchTerm: debouncedSearchTerm,
    geocodeByPlaceId,
    loading,
    onSelectionMade,
    getSuggestions,
  };
}
