import { FormField } from '../fields/FormField';
import { EditableJSONField, EditableUnit } from '../types';
import {
  AccessorialArray,
  AccessorialArrayData,
} from './accessorial-array/AccessorialArray';
import { Address, AddressData } from './address/Address';
import {
  AttributeArray,
  AttributeArrayData,
} from './attributes/AttributeArray';
import {
  CommodityArray,
  CommodityArrayData,
} from './commodity-array/CommodityArray';
import {
  CommodityFields,
  CommodityFieldsData,
} from './commodity-fields/CommodityFields';
import {
  CommodityIndexArray,
  CommodityIndexArrayData,
} from './commodity-index-array/CommodityIndexArray';
import {
  Dimension,
  DimensionData,
  Dimensions,
  DimensionsData,
} from './dimensions/Dimensions';
import {
  LineItemArray,
  LineItemArrayData,
} from './line-item-array/LineItemArray';
import {
  MileageFields,
  MileageFieldsData,
} from './mileage-fields/MileageFields';
import {
  ReferenceNumberArray,
  ReferenceNumberArrayData,
} from './reference-number-array/ReferenceNumberArray';
import { TagArray, TagArrayData } from './tags/TagArray';
import {
  TemperatureFields,
  TemperatureFieldsData,
} from './temperature-fields/TemperatureFields';
import { TimeFieldsData, TimeRange } from './time-range/TimeRange';
import { WeightFields, WeightFieldsData } from './weight-fields/WeightFields';

export function createBuiltinSpecializedBlock(
  data: EditableUnit,
  prefix: string
) {
  switch (data._type) {
    case 'WEIGHT':
      return (
        <WeightFields
          data={data as unknown as WeightFieldsData}
          prefix={prefix}
        />
      );
    case 'MILEAGE':
      return (
        <MileageFields
          data={data as unknown as MileageFieldsData}
          prefix={prefix}
        />
      );
    case 'TEMPERATURE':
      return (
        <TemperatureFields
          data={data as unknown as TemperatureFieldsData}
          prefix={prefix}
        />
      );
    case 'REFERENCE_NUMBER_ARRAY':
      return (
        <ReferenceNumberArray
          data={data as ReferenceNumberArrayData}
          prefix={prefix}
        />
      );
    case 'ATTRIBUTE_ARRAY':
      return (
        <AttributeArray data={data as AttributeArrayData} prefix={prefix} />
      );
    case 'ADDRESS':
      return <Address data={data as AddressData} prefix={prefix} />;
    case 'COMMODITY':
      return (
        <CommodityFields
          data={data as unknown as CommodityFieldsData}
          prefix={prefix}
        />
      );
    case 'COMMODITY_ARRAY':
      return (
        <CommodityArray
          data={data as unknown as CommodityArrayData}
          prefix={prefix}
        />
      );
    case 'LINE_ITEM_ARRAY':
      return <LineItemArray data={data as LineItemArrayData} prefix={prefix} />;
    case 'ACCESSORIAL_ARRAY':
      return (
        <AccessorialArray data={data as AccessorialArrayData} prefix={prefix} />
      );
    // TODO(max): Deprecate OPTIONAL_TIME_RANGE.
    case 'TIME_RANGE':
    case 'OPTIONAL_TIME_RANGE':
      return <TimeRange data={data as TimeFieldsData} prefix={prefix} />;
    case 'DIMENSIONS':
      return <Dimensions data={data as DimensionsData} prefix={prefix} />;
    case 'DIMENSION':
      return <Dimension data={data as DimensionData} prefix={prefix} />;
    case 'TAG_ARRAY':
      return <TagArray data={data as TagArrayData} prefix={prefix} />;
    case 'COMMODITY_INDEX_ARRAY':
      return (
        <CommodityIndexArray
          data={data as CommodityIndexArrayData}
          prefix={prefix}
        />
      );
    default:
      if (typeof data._value === 'string') {
        return <FormField data={data as EditableJSONField} prefix={prefix} />;
      }

      // TODO(jacob): Instead of throwing, should we fall back to
      // rendering as a generic object?
      throw new Error(`Unknown _type: ${data._type}`);
  }
}
