import { RatingMethod } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { GroupedRate } from '../components/rating-widgets/grouped-rates/types';
import { getDATRateViewUrl } from './getDATRateUrl';

export const getGroupedRatesUrl = ({
  rates,
  ratingMethod,
  quote,
}: {
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
  quote: Quote;
}): string | undefined => {
  if (ratingMethod === RatingMethod.DAT) {
    return getDATRateViewUrl(quote);
  }

  return rates.find((r) => r.metadata?.url)?.metadata?.url;
};
