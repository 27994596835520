export enum FeatureFlagName {
  FORWARDED_EMAIL_CLASSIFICATION = 'FORWARDED_EMAIL_CLASSIFICATION',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  QUOTE_UPDATE_FROM_TMS_IMPORT = 'QUOTE_UPDATE_FROM_TMS_IMPORT',
  BUILD_ORDER_BUTTON = 'BUILD_ORDER_BUTTON',
  BUILD_QUOTE_BUTTON = 'BUILD_QUOTE_BUTTON',
  ORDER_PRESUBMIT_VALIDATION = 'ORDER_PRESUBMIT_VALIDATION',
  QUOTES_CONDITIONAL_AUTO_REPLY = 'QUOTES_CONDITIONAL_AUTO_REPLY',
  SCHEDULING = 'SCHEDULING',
  PAUSE_MESSAGE_CLASSIFICATION = 'PAUSE_MESSAGE_CLASSIFICATION',
  EMAIL_CONVERSATIONS = 'EMAIL_CONVERSATIONS',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  TASKS_PAGE_V2 = 'TASKS_PAGE_V2',
}
