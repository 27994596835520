import { RatingMethod } from '@shared/generated/graphql';
import { CarrierRate, CustomerRate, Quote } from '@shared/types/quote';
import { groupRatesByMethod } from '@shared/utils/rates/groupRatesByMethod';
import {
  HasRatingMethod,
  selectNewestRatesOfEachMethod,
} from '@shared/utils/rates/rates';
import { Dictionary } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useRateErrorsContext } from '../../contexts/RateErrorContext';
import { MarketConditionsContext } from '../market-conditions/contexts/MarketConditionsContext';
import { useMarketConditionsMutation } from '../market-conditions/hooks/useMarketConditionsMutation';
import { RatingError } from './RatingError';
import { RatingWidget } from './RatingWidget';

type Rates = {
  carrierRates: CarrierRate[];
  customerRates: CustomerRate[];
};

type RatesByMethod = Record<RatingMethod, Rates>;

function groupRates(
  carrierRates: CarrierRate[],
  customerRate: (CustomerRate & HasRatingMethod)[]
): RatesByMethod {
  const carrierByMethod = groupRatesByMethod(carrierRates);
  const customerByMethod = groupRatesByMethod(customerRate);
  const methods = new Set([
    ...(Object.keys(carrierByMethod) as RatingMethod[]),
    ...(Object.keys(customerByMethod) as RatingMethod[]),
  ]);
  return combineRatesByMethod(
    Array.from(methods),
    carrierByMethod,
    customerByMethod
  );
}

export function shouldPreventRateComparisons(quote: Quote): boolean {
  const selectedCarrierRates = selectNewestRatesOfEachMethod(
    quote.carrierRates
  );
  const selectedCustomerRates = selectNewestRatesOfEachMethod(
    quote.customerRates
  );

  const groupedRates = groupRates(selectedCarrierRates, selectedCustomerRates);
  return (
    Object.keys(groupedRates).includes(RatingMethod.DAT) &&
    Object.keys(groupedRates).includes(RatingMethod.GREENSCREENS)
  );
}

function combineRatesByMethod(
  methods: RatingMethod[],
  carrierByMethod: Record<RatingMethod, CarrierRate[]>,
  customerByMethod: Record<RatingMethod, (CustomerRate & HasRatingMethod)[]>
): RatesByMethod {
  return methods.reduce((acc, method) => {
    acc[method] = {
      carrierRates: carrierByMethod[method] ?? [],
      customerRates: customerByMethod[method] ?? [],
    };
    return acc;
  }, {} as RatesByMethod);
}

type RatingWidgetsProps = {
  quote: Quote;
  setBuy: (val: number, createNewRate: boolean) => void;
  setLineItemsExpanded: Dispatch<SetStateAction<boolean>>;
  onExpandAnyRateWidget: (ratingMethod: RatingMethod) => void;
};

export function RatingWidgets(p: RatingWidgetsProps) {
  const selectedCarrierRates = selectNewestRatesOfEachMethod(
    p.quote.carrierRates
  );
  const selectedCustomerRates = selectNewestRatesOfEachMethod(
    p.quote.customerRates
  );

  const groupedRates = groupRates(selectedCarrierRates, selectedCustomerRates);

  const { rateErrors } = useRateErrorsContext();
  const errorMethods = filterNonErrorMethods(groupedRates, rateErrors);

  // NOTE(parlato): This is due to requirements from our DAT agreement which
  // stipulate that we cannot show rates from other providers when showing DAT
  const preventRateComparisons = shouldPreventRateComparisons(p.quote);
  const [focusedRatingWidget, setFocusedRatingWidget] =
    useState<RatingMethod>();

  const handleExpandAnyRateWidget = (ratingMethod: RatingMethod) => {
    setFocusedRatingWidget(ratingMethod);
    p.onExpandAnyRateWidget(ratingMethod);
  };

  // TODO(mike): Delete this by refactoring to handle market conditions for each
  // widget separately.
  const {
    fetchMarketConditionsMutation,
    forceFetchMarketConditionsMutation,
    marketConditions,
    loading: loadingMarketConditions,
  } = useMarketConditionsMutation(p.quote.id);

  return (
    <MarketConditionsContext.Provider
      value={{
        fetchMarketConditionsMutation,
        forceFetchMarketConditionsMutation,
        marketConditions,
        loading: loadingMarketConditions,
      }}
    >
      <div className="flex w-full flex-col items-center gap-[8px] text-gray-500">
        <div className="my-[8px] flex w-full flex-col gap-[8px]">
          {Object.entries(groupedRates)
            .map(([method, rates]) => (
              <RatingWidget
                quote={p.quote}
                key={method}
                ratingMethod={method as RatingMethod}
                carrierRates={rates.carrierRates}
                customerRates={rates.customerRates}
                onExpandAnyRateWidget={handleExpandAnyRateWidget}
                focusedRatingWidget={focusedRatingWidget}
                preventRateComparisons={preventRateComparisons}
              />
            ))
            .filter((widget) => widget !== null)}
        </div>
        {errorMethods.map((method) => (
          <RatingError
            quote={p.quote}
            key={method}
            ratingMethod={method}
            onExpandAnyRateWidget={p.onExpandAnyRateWidget}
            preventRateComparisons={preventRateComparisons}
          />
        ))}
      </div>
    </MarketConditionsContext.Provider>
  );
}

function filterNonErrorMethods(
  groupedRates: RatesByMethod,
  rateErrors: Dictionary<string>
): RatingMethod[] {
  const rateErrorsWithoutGroupedRates = Object.entries(rateErrors).reduce(
    (acc, [ratingMethod, error]) => {
      if (!error || groupedRates[ratingMethod as RatingMethod]) {
        return acc;
      }

      return { ...acc, [ratingMethod]: error };
    },
    {} as Record<RatingMethod, string>
  );
  return Object.keys(rateErrorsWithoutGroupedRates).map(
    (ratingMethod) => ratingMethod as RatingMethod
  );
}
