import { DropdownMenu } from '@shared/components/dropdown-menu';
import { Text } from '@shared/components/text';
import { StyleProps } from '@shared/utils';
import {
  CommodityTemplate,
  CompletedFreightOrderTemplate,
  WithMetadata,
} from 'clerk_common/templates/freight_order/types';
import { getArrayValue, getObjectValue } from 'clerk_common/templates/getValue';
import { CompletedTemplateChildConfigs } from 'clerk_common/templates/types';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableJSONField, FORM_STATE_FIELD } from '../../types';
import { CommodityIndexElement, el } from './CommodityIndexArray';

type CommodityIndexProps = {
  idx: number;
  data: CommodityIndexElement;
  prefix?: string;
};

type Commodity = WithMetadata<CommodityTemplate<CompletedTemplateChildConfigs>>;

export const CommodityIndex = ({ data, prefix }: CommodityIndexProps) => {
  const { extractedData, save } = useInternalFormContext();
  const { setValue } = useFormContext<EditableJSONField>();

  const value = extractedData._value as CompletedFreightOrderTemplate['_value'];
  const commodities = getArrayValue(
    value.commodities ?? { _value: [] }
  ) as Commodity[];

  const thisIndex = getObjectValue(data.index);
  const thisIndexInt = thisIndex ? parseInt(thisIndex) : null;
  const thisCommodity = !isNil(thisIndexInt) ? commodities[thisIndexInt] : null;

  const setSelectedIdx = (newIdx: number) => {
    setValue(
      `${prefix}index.${FORM_STATE_FIELD}` as typeof FORM_STATE_FIELD,
      `${newIdx}`
    );
    save();
  };

  return (
    <div className={el`commodity-element`}>
      <>
        {thisCommodity ? (
          <CommodityDetails commodity={thisCommodity} />
        ) : (
          <Text type="body-sm" className="italic text-gray-500">
            Select a commodity
          </Text>
        )}
      </>
      <CommoditySelect
        commodities={commodities}
        selectedIdx={thisIndexInt}
        setSelectedIdx={setSelectedIdx}
        className={el`commodity-select`}
      />
    </div>
  );
};

type CommodityDetailsProps = {
  commodity: Commodity;
};

const CommodityDetails = ({ commodity }: CommodityDetailsProps) => {
  const desc = getObjectValue(commodity.description);
  const itemRef = commodity.itemReference
    ? getObjectValue(commodity.itemReference)
    : null;

  const weight = commodity.weight;
  const weightValue = weight ? getObjectValue(weight._value.weight) : null;
  const weightUnits = weight ? getObjectValue(weight._value.units) : null;
  const allWeightData = weightValue && weightUnits;
  const weightString = allWeightData
    ? `${weightValue} ${weightUnits.toLowerCase()}`
    : null;

  const topString = [desc, itemRef].filter(Boolean).join(' · ');
  const bottomString = weightString;

  return (
    <div>
      <Text isHeavy type="body-xs" className="text-gray-500">
        {topString}
      </Text>
      {bottomString && (
        <Text type="body-xs" className="text-gray-500">
          {bottomString}
        </Text>
      )}
    </div>
  );
};

type CommoditySelectProps = StyleProps & {
  commodities: Commodity[];
  selectedIdx: number | null;
  setSelectedIdx: (idx: number) => void;
};

const CommoditySelect = (p: CommoditySelectProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <div className={p.className}>
          <MdEdit className="text-gray-700" size={16} />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={el`select-menu-content`} align={'end'}>
        {p.commodities.length === 0 && (
          <div className={el`no-results`}>
            <Text type="body-xs">No results found</Text>
          </div>
        )}
        <div className={el`commodity-options`}>
          {p.commodities.map((commodity, idx) => {
            const isLast = idx + 1 === p.commodities.length;
            const isSelected = idx === p.selectedIdx;
            return (
              <DropdownMenu.Item
                key={idx}
                className={clsx(isLast && 'rounded-b-lg')}
                onSelect={() => p.setSelectedIdx(idx)}
              >
                <CommodityOption
                  commodity={commodity}
                  isSelected={isSelected}
                />
              </DropdownMenu.Item>
            );
          })}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

type CommodityOptionProps = {
  commodity: Commodity;
  isSelected: boolean;
};

const CommodityOption = ({ commodity }: CommodityOptionProps) => {
  return (
    <div className={el`commodity-option`}>
      <CommodityDetails commodity={commodity} />
    </div>
  );
};
