import { z } from 'zod';
import { AutoReplySettingsSchema } from './originator';

export enum QuoteAutoReplyScope {
  DISABLED = 'DISABLED',
  DRAFT = 'DRAFT',
  SEND = 'SEND',
}

export enum TeamMemberRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export const TeamSettingsSchema = z
  .object({
    // NOTE(parlato): Ideally this would live in the autoReply object; I am keeping
    // it here to maintain the shape of Originator settings; quoteAutoReplyScope is
    // a column on Originator
    quoteAutoReplyScope: z.nativeEnum(QuoteAutoReplyScope)?.optional(),
    autoReply: AutoReplySettingsSchema.optional(),
  })
  .strict();
export type TeamSettings = z.infer<typeof TeamSettingsSchema>;
