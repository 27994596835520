import { Modal } from '@shared/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import {
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
} from 'react-hook-form';

type FormType = { [key: string]: any };
type FormRenderer = (p: EveryStepProps) => ReactNode;
type WizardStep = {
  stepKey: string;
  title?: string;
  hideTitle?: boolean;
  className?: string;
  getPageNumber?: (currentStep: number, maxStep: number) => string;
  renderForm: FormRenderer;
  // TODO(parlato): Can we properly type this?
  onStepSubmit?: (data: any) => void;
};
type WizardProps = StyleProps & {
  steps: WizardStep[];
  onSubmit?: (data: any) => void;
  shouldSquashPages?: boolean;
  initialData?: FormType;
};

const ROOT = makeRootClassName('Wizard');
const el = makeElementClassNameFactory(ROOT);

export function Wizard(p: WizardProps) {
  const idxToKey = p.steps.map((step) => step.stepKey);
  const minStep = 0;
  const maxStep = p.steps.length - 1;

  const [currentStep, setCurrentStep] = useState(minStep);
  const [formData, setFormData] = useState<FormType>(p.initialData ?? {});

  const saveData = (data: any) => {
    const currentKey = idxToKey[currentStep];
    const newData = { ...formData, [currentKey]: data };
    setFormData(newData);
    return newData;
  };

  const toNextStep = (stepData: any) => {
    saveData(stepData);
    setCurrentStep(Math.min(currentStep + 1, maxStep));
  };

  const toPrevStep = (stepData: any) => {
    saveData(stepData);
    setCurrentStep(Math.max(currentStep - 1, minStep));
  };

  const submitAll = (currentStepData: any) => {
    const newFormData = saveData(currentStepData);
    if (p.shouldSquashPages) {
      p.onSubmit?.(squashPages(newFormData));
    } else {
      p.onSubmit?.(newFormData);
    }
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <WizardProgress currentStep={currentStep} maxStep={maxStep} />
      <div className={el`step-container`}>
        {p.steps.map((step, index) => {
          return (
            <WizardStepRenderer
              title={step.title}
              className={step.className}
              key={`form-step-${index}`}
              thisStep={index}
              minStep={minStep}
              activeStep={currentStep}
              maxStep={maxStep}
              defaultValues={formData[idxToKey[index]]}
              submitAll={submitAll}
              toNextStep={async (data) => {
                if (step.onStepSubmit) {
                  await step.onStepSubmit(data);
                }
                toNextStep(data);
              }}
              toPrevStep={toPrevStep}
              renderForm={step.renderForm}
              hideTitle={step.hideTitle}
              pageNumber={step.getPageNumber?.(currentStep, maxStep)}
              formData={formData}
            />
          );
        })}
      </div>
    </div>
  );
}

type WizardProgressProps = {
  currentStep: number;
  maxStep: number;
};
function WizardProgress(p: WizardProgressProps) {
  const isComplete = Array.from(Array(p.maxStep + 1).keys()).map((_, index) => {
    return index <= p.currentStep;
  });
  return (
    <div className={el`progress-container`}>
      {isComplete.map((isComp, index) => {
        const isFirstStep = index === 0;
        const isLastStep = index === p.maxStep;
        return (
          <div
            key={`progress-${index}`}
            className={clsx([
              el`progress-piece`,
              {
                'is-complete': isComp,
                'is-first': isFirstStep,
                'is-last': isLastStep,
              },
            ])}
          />
        );
      })}
    </div>
  );
}

type WizardState = {
  minStep: number;
  activeStep: number;
  maxStep: number;
  submitAll: (currentFormData: any) => void;
  toNextStep: (data: any) => void;
  toPrevStep: (data: any) => void;
};

export type EveryStepProps = WizardState & {
  thisStep: number;
  defaultValues?: any;
  hideTitle?: boolean;
  pageNumber?: string;
  formData: FormType;
};

type WizardStepRendererProps = StyleProps &
  EveryStepProps & {
    title?: string;
    renderForm: (p: EveryStepProps) => ReactNode;
  };

function WizardStepRenderer(p: WizardStepRendererProps) {
  if (p.thisStep !== p.activeStep) {
    return null;
  }

  return (
    <div className={clsx(el`step-renderer`, p.className)}>
      <div>
        <Modal.Header
          pageNumber={p.pageNumber}
          hasCloseButton
          title={p.hideTitle ? '' : p.title || ''}
        />
      </div>
      {p.renderForm({ ...p })}
    </div>
  );
}

const squashPages = (formData: FormType) => {
  return Object.values(formData).reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});
};

type WizardFooterProps<T> = StyleProps &
  EveryStepProps & {
    handleSubmit: UseFormHandleSubmit<T extends FieldValues ? T : FieldValues>;
    getValues: UseFormGetValues<T extends FieldValues ? T : FieldValues>;
    isWelcomeStep?: boolean;
    loadingSubmit?: boolean;
    submitLabel?: string;
  };
export function WizardFooter<T>(p: WizardFooterProps<T>) {
  const isLastStep = p.thisStep === p.maxStep;
  const buttonText = determineButtonText({
    isWelcomeStep: p.isWelcomeStep ?? false,
    isLastStep,
    submitLabel: p.submitLabel,
  });
  const buttonAction = isLastStep ? p.submitAll : p.toNextStep;
  return (
    <div>
      <Modal.Footer className={p.className}>
        {p.thisStep !== p.minStep && (
          <Modal.FooterButton
            isDisabled={p.thisStep === p.minStep}
            onPress={() => p.toPrevStep(p.getValues())}
          >
            Previous
          </Modal.FooterButton>
        )}
        <Modal.FooterButton
          isPrimary={isLastStep}
          onPress={() => p.handleSubmit(buttonAction)()}
          isDisabled={p.loadingSubmit}
        >
          {buttonText}
        </Modal.FooterButton>
      </Modal.Footer>
    </div>
  );
}
type DetermineButtonArgs = {
  isWelcomeStep: boolean;
  isLastStep: boolean;
  startLabel?: string;
  submitLabel?: string;
  nextLabel?: string;
};
const determineButtonText = (args: DetermineButtonArgs) => {
  if (args.isWelcomeStep) return args.startLabel ?? 'Start';
  if (args.isLastStep) return args.submitLabel ?? 'Submit';
  return args.nextLabel ?? 'Next';
};

type WizardStepContainerProps<T> = StyleProps & {
  children: React.ReactNode;
  handleSubmit?: UseFormHandleSubmit<T extends FieldValues ? T : FieldValues>;
  toNextStep: (data: any) => void;
};

export function WizardStepContainer<T>(p: WizardStepContainerProps<T>) {
  return (
    <form
      className={clsx(p.className, 'flex h-full w-full flex-col')}
      onSubmit={p.handleSubmit ? p.handleSubmit(p.toNextStep) : undefined}
    >
      {p.children}
    </form>
  );
}

export type WizardStepProps<T> = {
  thisStep: number;
  minStep: number;
  activeStep: number;
  maxStep: number;
  submitAll: (currentFormData: T) => void;
  onClose?: () => void;
  toPrevStep: (data: T) => void;
  toNextStep: (data: T) => void;
  defaultValues?: T;
  loadingSubmit?: boolean;
  formData: FormType;
};
