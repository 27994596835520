import { AccordionProps } from '@mui/material/Accordion';
import { makeRootClassName } from '@shared/utils';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

const ROOT = makeRootClassName('ExpandablePanel');

type ExpandablePanelProps = Omit<AccordionProps, 'children'> & {
  children: ReactNode;
  summary: ReactNode;
  collapsePanelComponent?: ReactNode;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

export const ExpandablePanel = (props: ExpandablePanelProps) => {
  const onClick = () => {
    if (props.disabled) return;
    props.setExpanded(!props.expanded);
  };

  return (
    <div className={ROOT}>
      <div>
        <div onClick={onClick}>{props.summary}</div>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: props.expanded ? 'auto' : 0 }}
        transition={{ duration: 0.2 }}
      >
        {props.expanded && (
          <>
            {props.children}
            {props.collapsePanelComponent ? (
              <div onClick={onClick}>{props.collapsePanelComponent}</div>
            ) : null}
          </>
        )}
      </motion.div>
    </div>
  );
};
